import { useTranslation } from "react-i18next";
import WithdrawAllProducts from '../../imgs/withdraw-all-products.png'
import WithdrawalSpecificProducts from '../../imgs/withdrawal-specific-products.png'
import UpdatePricesNow from '../../imgs/update-prices-now.png'
import { Link } from "react-router-dom";

const PullProducts = (props) => {
    const { t } = useTranslation();
    const role = localStorage.getItem('auth_role')

    const handleRefreshClick = () => {
        window.location.reload();
    };

    return (
        <>
            {role !== 'Manager' ? (
                <div className="card-gold py-4">
                    <h2 className="title text-center mb-4">{t("layoutProducts.pullProducts")}</h2>
                    <div className="buttons mx-auto">
                        <button onClick={() => props.pullProducts()}>
                            <img src={WithdrawAllProducts} alt="Fluent Phone Update" />
                            {t("layoutProducts.pullAllProducts")}
                        </button>
                        <Link to="/unpulled-products" className="btn button">
                            <img className="icon" src={WithdrawalSpecificProducts} alt="Fluent Phone Update" />
                            {t("layoutProducts.pullSpecificProducts")}
                        </Link>
                        <button onClick={handleRefreshClick}>
                            <img className="icon" src={UpdatePricesNow} alt="Fluent Phone Update" />
                            {t("layoutProducts.productsUpdate")}
                        </button>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default PullProducts;
