const withAuth = (WrappedComponent) => {
    const Wrapper = (props) => {        
        if (!(localStorage.getItem('auth_token')/* || localStorage.getItem('access_token')*/)) {
            window.location.href = '/login';
            return null;
        }

        return <WrappedComponent {...props} />;
    };

    return Wrapper;
};

export default withAuth;
