import { useState } from "react";
import { useTranslation } from "react-i18next";
import Import from '../../imgs/import.png'
import Export from '../../imgs/export.png'
import Search from '../../imgs/search.png'
import SearchOff from '../../imgs/search-off.png'
import axios from "../../lib/axios";
import swal from "sweetalert";

const FilterProduct = (props) => {
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = useState(null);
    const storeId = localStorage.getItem("store_id");
    const role = localStorage.getItem('auth_role')
    const platform = localStorage.getItem('platform')

    const exportProducts = () => {
        const exportProductsEndpoint = platform === 'Salla' ? 'export-products' : 'zidExport-products';
        axios.get(`/api/${exportProductsEndpoint}?store_id=${storeId}`, { responseType: 'blob' })
            .then(response => {
                // Create a Blob object containing the Excel file data
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Create a download link for the Blob
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'products.xlsx'; // Specify the file name here
                a.click();

                // Release the object URL
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                // 
            });
    }

    const handleFileEmportChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleUpload = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);

            const store_id = localStorage.getItem('store_id');
            formData.append('store_id', store_id);

            const settingsEndpoint = platform === 'Salla' ? 'upload-excel' : 'zidUpload-excel';
            axios.post(`/api/${settingsEndpoint}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                // Handle the response if needed
                console.log(response.data);
                swal('Success', 'The products have been imported successfully', 'success');
            })
            .catch((error) => {
                // Handle errors if any
                console.error(error);
                // 
            });
        }
    };

    return (
        <>
            <div className="d-none d-lg-block">
                <div className="d-flex justify-content-between">
                    {role !== 'Manager' ? (
                        <div className="card-gold py-5" style={{ width: "49.5%" }}>
                            <h2 className="title text-center mb-5">{t('importAndExport.importAndExport')}</h2>
                            <div className="import-card d-flex justify-content-between align-items-center px-4 mb-5">
                                <div>
                                    <h4>{t('importAndExport.import')}</h4>
                                </div>
                                <div className="d-flex import">
                                    <input
                                        type="file"
                                        accept=".xlsx"
                                        id="fileInput"
                                        className="file-input"
                                        onChange={handleFileEmportChange}
                                    />
                                    <button className="choose-button"  onClick={() => document.getElementById('fileInput').click()}>
                                        {t('importAndExport.selectFile')}   
                                    </button>
                                    <div className="file-display d-flex align-items-center">
                                        {selectedFile ? selectedFile.name : `${t('importAndExport.noFileSelected')}` }
                                    </div>
                                    <button className="import-button" onClick={handleUpload} disabled={!selectedFile}>
                                        <img src={Import} alt="Import" />
                                        <span>{t('importAndExport.impor')}</span> 
                                    </button>
                                </div>
                            </div>
                            <div className="export-card d-flex justify-content-between align-items-center px-4">
                                <div>
                                    <h4>{t('importAndExport.export')}</h4>
                                </div>
                                <div className="d-flex export">
                                    <button className="export-button" onClick={exportProducts}>
                                        <img src={Export} alt="Export" />
                                        <span>{t('importAndExport.expor')}</span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="card-gold search-date py-5" style={{ width: "49.5%" }}>
                        <h2 className="title text-center mb-4">{t('layoutProducts.filterProducts')}</h2>
                        <form onSubmit={props.handleSubmit}>
                            <div className="search-card d-flex justify-content-center px-1 mb-4">
                                <div className="d-flex search">
                                    <button className="search-button">
                                        {t('layoutProducts.periodFrom')}
                                    </button>
                                    <input 
                                        type="date"
                                        value={props.startDate}
                                        onChange={props.handleStartDateChange}
                                    />
                                </div>
                            </div>
                            <div className="search-card d-flex justify-content-center px-1 mb-5">
                                <div className="d-flex search">
                                    <button className="search-button">
                                        {t('layoutProducts.periodTo')}        
                                    </button>
                                    <input 
                                        type="date"
                                        value={props.endDate}
                                        onChange={props.handleEndDateChange}
                                    />
                                </div>
                            </div>
                            <div className="search-buttons d-flex justify-content-center">
                                <button type="submit">
                                    <img className="icon" src={Search} alt="Search" />
                                    {t("layoutProducts.search")}
                                </button>
                                <button>
                                    <img className="icon" src={SearchOff} alt="Search" />
                                    {t("layoutProducts.clearSearch")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="d-block d-lg-none">
                <div className="card-gold py-5">
                    <h2 className="title text-center mb-5">{t('importAndExport.importAndExport')}</h2>
                    <div className="import-card d-flex justify-content-between align-items-center px-4 mb-5">
                        <div>
                            <h4 style={{ fontSize: "13px" }}>{t('importAndExport.impor')}</h4>
                        </div>
                        <div className="d-flex import" style={{ height: "30px" }}>
                            <input
                                type="file"
                                accept=".xlsx"
                                id="fileInput"
                                className="file-input"
                                onChange={handleFileEmportChange}
                            />
                            <button className="choose-button" style={{ fontSize: "10px" }} onClick={() => document.getElementById('fileInput').click()}>
                                {t('importAndExport.selectFile')}     
                            </button>
                            <div className="file-display d-flex align-items-center" style={{ fontSize: "8px" }}>
                                {selectedFile ? selectedFile.name : `${t('importAndExport.noFileSelected')}`}
                            </div>
                            <button className="import-button"  onClick={handleUpload} disabled={!selectedFile}>
                                <img src={Import} alt="Import" className="m-auto" />
                            </button>
                        </div>
                    </div>
                    <div className="export-card d-flex justify-content-between align-items-center px-4">
                        <div>
                            <h4 style={{ fontSize: "13px" }}>{t('importAndExport.export')}</h4>
                        </div>
                        <div className="d-flex export" style={{ height: "30px" }}>
                            <button className="export-button" onClick={exportProducts}>
                                <img src={Export} alt="Export" />
                                <span>{t('importAndExport.expor')}</span> 
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-gold search-date py-5">
                    <h2 className="title text-center mb-4">{t('layoutProducts.filterProducts')}</h2>
                    <form onSubmit={props.handleSubmit}>
                        <div className="search-card d-flex justify-content-center px-1 mb-4">
                            <div className="d-flex search">
                                <button className="search-button">
                                    {t('layoutProducts.periodFrom')}     
                                </button>
                                <input 
                                    type="date"
                                    value={props.startDate}
                                    onChange={props.handleStartDateChange}
                                />
                            </div>
                        </div>
                        <div className="search-card d-flex justify-content-center px-1 mb-5">
                            <div className="d-flex search">
                                <button className="search-button">
                                    {t('layoutProducts.periodTo')}       
                                </button>
                                <input 
                                    type="date"
                                    value={props.endDate}
                                    onChange={props.handleEndDateChange}
                                />
                            </div>
                        </div>
                        <div className="search-buttons d-flex justify-content-center">
                            <button type="submit">
                                <img className="icon" src={Search} alt="Search" />
                                {t("layoutProducts.search")}
                            </button>
                            <button  style={{ width: "40%" }}>
                                <img className="icon" src={SearchOff} alt="Search" />
                                {t("layoutProducts.clearSearch")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default FilterProduct;
