import { useEffect, useState } from "react";
import CostChart from "./CostChart"
import TotalChart from "./TotalChart"
import axios from "../../lib/axios";
import {useTranslation} from "react-i18next"

const Statistics = () => {
    const [costDiscount, setCostDiscount] = useState(0)
    const [increaseOnTotal, setIncreaseOnTotal] = useState(0)
    const storeId = localStorage.getItem('store_id');
    const {t} = useTranslation()
    const platform = localStorage.getItem('platform')

    useEffect(() => {
        const settingsEndpoint = platform === 'Salla' ? 'settings' : 'zidSettings';
        axios.get(`/api/${settingsEndpoint}?store_id=${storeId}`).then(res => {
            setCostDiscount(res.data.costDiscount);
            setIncreaseOnTotal(res.data.increaseOnTotal);
        }).catch(error => {
            console.error(error);
        });
    }, []);

    return (
        <>
            <div className="d-none d-lg-block">
                <div className="card-gold d-flex align-items-center justify-content-between px-2 py-4 my-4">
                    <div className="card-statistics d-flex align-items-center">
                        <div className="title">
                            <h3 className="mx-auto">{t('layoutProducts.discountPercentage')} : {costDiscount}%</h3>
                        </div>
                        <div className="chart">
                            <CostChart costDiscount={costDiscount} />
                        </div>
                    </div>
                    <div className="bar"></div>
                    <div className="card-statistics d-flex align-items-center">
                        <div className="title">
                            <h3 className="mx-auto">{t('layoutProducts.percentageOfIncrease')} : {increaseOnTotal}%</h3>
                            <p>{t('layoutProducts.text')}</p>
                        </div>
                        <div className="chart">
                            <TotalChart increaseOnTotal={increaseOnTotal} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-block d-lg-none">
                <div className="card-gold px-2 py-4 my-4">
                    <div className="card-statistics-mobile">
                        <div className="title-mobile">
                            <h3 className="mr-3" style={{ fontSize: "22px" }}>{t('layoutProducts.discountPercentage')} : {costDiscount}%</h3>
                        </div>
                        <div className="chart mx-auto">
                            <CostChart costDiscount={costDiscount} />
                        </div>
                    </div>
                    <div className="bar-mobile mx-auto" style={{ background: "#BF972580", width: "180px", height: "2px", marginBottom: "10%", marginTop: "7%" }}></div>
                    <div className="card-statistics-mobile">
                        <div className="title-mobile">
                            <h3 className="mr-3" style={{ fontSize: "22px" }}>{t('layoutProducts.percentageOfIncrease')} : {increaseOnTotal}%</h3>
                            <p className="mr-3" style={{ fontSize: "11.28px", color: "#FF0000" }}>{t('layoutProducts.text')}</p>
                        </div>
                        <div className="chart mx-auto">
                            <TotalChart increaseOnTotal={increaseOnTotal} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Statistics