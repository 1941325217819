import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom"
import axios from "../../lib/axios";
import {useTranslation} from "react-i18next"
import Logo from '../../imgs/smalltransparent.png'
import Home from '../../imgs/home.png'
import Allprod from '../../imgs/allprod.png'
import Productscomp from '../../imgs/productscomp.png'
import Productsnotcomp from '../../imgs/productsnotcomp.png'
import Deletproducts from '../../imgs/deletproducts.png'
import Users from '../../imgs/users.png'
import Roles from '../../imgs/roles.png'
import Notific from '../../imgs/notific.png'
import Conditions from '../../imgs/conditions.png'
import Privacy from '../../imgs/privacy.png'
import Packages from '../../imgs/packages.png'
import Setting from '../../imgs/setting.png'

const Sidebar = () => {
    const [settingsExists, setSettingsExists] = useState(false);
    const storeId = localStorage.getItem('store_id');
    const {t} = useTranslation()
    const [logoType, setLogoType] = useState(localStorage.getItem('logo'));
    const role = localStorage.getItem('auth_role')
    const platform = localStorage.getItem('platform')

    const selectedStyle = {
        backgroundColor: "white !important",
        color: "slategray"
    }

    useEffect(() => {
        const settingsEndpoint = platform === 'Salla' ? 'settings' : 'zidSettings';
        axios.get(`/api/${settingsEndpoint}?store_id=${storeId}`).then(res => {
            setSettingsExists(res.data.settingsExists);
        }).catch(error => {
            console.error(error);
        });
    }, [storeId]);

    useEffect(() => {
        setLogoType(localStorage.getItem('logo'));
    }, []);

    useEffect(() => {
        const handleLogoChange = () => {
            setLogoType(localStorage.getItem('logo'));
        };
    
        window.addEventListener('logo', handleLogoChange);

        return () => {
            window.removeEventListener('logo', handleLogoChange);
        };
    }, []);

    return (
        <>
            <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{ background: "#010101" }}>
                <div className="sidebar">
                    <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="mb-3 mx-auto">
                            <Link to="/" className="nav-link">
                            {logoType === 'bigLogo' ? (
                                <img src={Logo} alt="logo" width={150} />
                            ) : logoType === 'smallLogo' ? (
                                <img src={Logo} alt="logo" width={40} />
                            ) : null}
                            </Link>
                        </li>
                            <li className="nav-item">
                                <NavLink exact to="/" className="nav-link" activeStyle={selectedStyle}>
                                    <img className="sid-icon" src={Home} alt="Home" width={30} height={30} />
                                    <p>
                                        {t('sidebar.dashboard')}
                                    </p>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                            <NavLink exact to="all-products" className="nav-link" activeStyle={selectedStyle}>
                                <img className="sid-icon" src={Allprod} alt="All products" />
                                <p>{t('sidebar.allProducts')}</p>
                            </NavLink>
                            </li>
                            <li className="nav-item">
                            <NavLink to="completed-products" exact className="nav-link" activeStyle={selectedStyle}>
                                <img className="sid-icon" src={Productscomp} alt="Completed products" />
                                <p>{t('sidebar.completedProducts')}</p>
                            </NavLink>
                            </li>
                            <li className="nav-item">
                            <NavLink to="products-not-completed" exact className="nav-link" activeStyle={selectedStyle}>
                                <img className="sid-icon" src={Productsnotcomp} alt="Products not completed" />
                                <p>{t('sidebar.productsNotCompleted')}</p>
                            </NavLink>
                            </li>
                            <li className="nav-item">
                            <NavLink to="deleted-products" exact className="nav-link" activeStyle={selectedStyle}>
                                <img className="sid-icon" src={Deletproducts} alt="Deleted products" />
                                <p>{t('sidebar.deletedProducts')}</p>
                            </NavLink>
                            </li>
                        {role !== 'Manager' ? (
                            <>
                                <li className="nav-item">
                                    <NavLink to="users" exact className="nav-link" activeStyle={selectedStyle}>
                                        <img className="sid-icon" src={Users} alt="Users" />
                                        <p>{t('sidebar.users')}</p>
                                    </NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink to="roles" exact className="nav-link" activeStyle={selectedStyle}>
                                        <img className="sid-icon" src={Roles} alt="Roles" />
                                        <p>{t('sidebar.roles')}</p>
                                    </NavLink>
                                </li> */}
                            </>
                        ) : (
                            ''
                        )}
                        <>
                            {role !== 'Manager' ? (
                                <li className="nav-item">
                                    <NavLink to="notifications" exact className="nav-link" activeStyle={selectedStyle}>
                                        <img className="sid-icon" src={Notific} alt="Notifications" />
                                        <p>{t('sidebar.notifications')}</p>
                                    </NavLink>
                                </li>
                            ) : (
                                ''
                            )}
                            {/* <li className="nav-item">
                                <NavLink to="terms-and-conditions" exact className="nav-link" activeStyle={selectedStyle}>
                                    <img className="sid-icon" src={Conditions} alt="Conditions" />
                                    <p>{t('sidebar.termsAndConditions')}</p>
                                </NavLink>
                            </li> */}
                            <li className="nav-item">
                                <NavLink to="policy" exact className="nav-link" activeStyle={selectedStyle}>
                                    <img className="sid-icon" src={Privacy} alt="Privacy policy" />
                                    <p>{t('sidebar.policy')}</p>
                                </NavLink>
                            </li>
                            {role !== 'Manager' ? (
                                <>
                                <li className="nav-item">
                                    <NavLink to="plans" exact className="nav-link" activeStyle={selectedStyle}>
                                        <img className="sid-icon" src={Packages} alt="Packages" />
                                        <p>{t('sidebar.packages')}</p>
                                    </NavLink>
                                </li>

                                {settingsExists === true ? (
                                    <li className="nav-item">
                                        <NavLink to="settings" exact className="nav-link" activeStyle={selectedStyle}>
                                            <img className="sid-icon" src={Setting} alt="Settings" />
                                            <p>{t('sidebar.settings')}</p>
                                        </NavLink>
                                    </li>
                                ) : (
                                    
                                    <li className="nav-item">
                                        <NavLink to="create-settings" exact className="nav-link" activeStyle={selectedStyle}>
                                            <img className="sid-icon" src={Setting} alt="Settings" />
                                            <p>{t('sidebar.createSettings')}</p>
                                        </NavLink>
                                    </li>
                                )}
                            </>
                            ) : (
                                ''
                            )}
                        </>
                    </ul>
                    </nav>
                </div>
            </aside>
        </>
    )
}

export default Sidebar