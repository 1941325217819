import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import axios from "../../lib/axios";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import withAuthAdmin from "../../lib/withAuthAdmin";
import { useTranslation } from "react-i18next";
import gridiconsAdd from '../../imgs/gridicons_add.png'

const Users = () => {
    const [users, setUsers] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [perPage, setPerPage] = useState(10);
    const storeId = localStorage.getItem('store_id');
    const role = localStorage.getItem('auth_role')
    const { t } = useTranslation();
    const platform = localStorage.getItem('platform')

    useEffect(() => {
        getUsersData(activePage, perPage);
    }, [activePage, perPage]);

    async function getUsersData(pageNumber, pageSize) {
        try {
            const usersEndpoint = platform === 'Salla' ? 'users' : 'zidUsers';
            const response = await axios.get(`/api/${usersEndpoint}?page=${pageNumber}&per_page=${pageSize}&store_id=${storeId}`);
            setUsers(response.data.users);
        } catch (error) {
            console.error(error);
        }
    }

    const handleSearch = useCallback(async () => {
        try {
            const searchUsersEndpoint = platform === 'Salla' ? 'searchUsers' : 'searchZidUsers';
            const response = await axios.get(`/api/${searchUsersEndpoint}`, {
                params: { 
                    searchTerm,
                    store_id: storeId 
                },
            });

            setUsers(response.data.users);
        } catch (error) {
            console.error(error);
        }
    }, [searchTerm]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleSearch();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, handleSearch]);

    async function deleteUser(id) {
        try {
            const deleteUserEndpoint = platform === 'Salla' ? 'users' : 'zidUsers';
            const response = await axios.delete(`/api/${deleteUserEndpoint}/${id}`);
            console.log(response.data.message);
            getUsersData(activePage, perPage);
            swal("Success", `${t('layout.deleteSuccessfully')}`, "success");
        } catch (error) {
            console.error(error.response.data.message);
        }
    }

    function handlePageChange(pageNumber) {
        setActivePage(pageNumber);
    }

    function handlePerPageChange(event) {
        setPerPage(parseInt(event.target.value));
        setActivePage(1);
    }

    function renderUserList() {
        const { data, current_page, per_page, total } = users;
        return (
        <>
            <div className="content-wrapper">
                <div className="second-content-wrapper m-4 py-4 rounded rounded-10">
                    <div className='d-flex justify-content-between align-items-center px-4 py-3'>
                        <div className="mb-3">
                            <h3 style={{ fontSize: "19.04px" }}>{t('users.title')}</h3>
                        </div>
                        <Link to="/create-User" className="add-product-btn border-0 btn">
                            <img src={gridiconsAdd} alt="Add product" />
                            {t('users.add')}
                        </Link>
                    </div>

                    <div className="d-none d-lg-block">
                        <div className='d-flex justify-content-between align-items-center px-4 py-3'>
                            <form onSubmit={handleSearch}>
                                <div className="input-group" style={{ width: "300px", height: "40px" }}>
                                    <input 
                                        type="search" className="form-control form-control-lg" placeholder={t('users.search')}
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <button type='button' className="btn btn-lg btn-default" style={{ background: "#CAAE76" }}>
                                            <i className="fa fa-search text-white" />
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div>
                                {t('users.show')}
                                <select value={perPage} onChange={handlePerPageChange} style={{ width: "80px", height: "40px", borderRadius: "5px", margin: "0 24px 0 24px" }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                {t('users.entries')}
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                        <div className="row mt-3">
                            <div className="col-12">
                            <div className="card py-3 px-3">
                                <div className="card-body table-responsive p-0" style={{ boxhadow: "0px 4.525312900543213px 45.253143310546875px 0px #0000001A" }}>
                                <table className="table table-hover text-nowrap">
                                    <thead>
                                    <tr>
                                        <th className="text-center">#{t('users.id')}</th>
                                        <th className="text-center">{t('users.name')}</th>
                                        <th className="text-center">{t('users.role')}</th>
                                        <th className="text-center">{t('users.action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.map((user) => (
                                        <tr key={user.id}>
                                            <td style={{ color: "#CAAE76" }} className="text-center">{user.id}</td>
                                            <td style={{ color: "#909090" }} className="text-center">{user.name}</td>
                                            <td style={{ color: "#CAAE76" }} className="text-center">{user.role_name}</td>
                                            {/* {localStorage.getItem('auth_role') === 'Super Admin' || user.role_name !== 'Super Admin' ? (
                                                <td className="text-center">
                                                <Link className="border-0 bg-transparent" to={`/edit-user/${user.id}`}>
                                                    <i className="fas fa-pencil-alt nav-icon" style={{ color: "#CAAE76" }}></i>
                                                </Link>
                                                <button className="border-0 bg-transparent" onClick={() => deleteUser(user.id)}>
                                                    <i className="fas fa-trash-alt trash nav-icon text-danger"></i>
                                                </button>
                                                </td>
                                            ) : (
                                                <td className="text-center"></td>
                                            )} */}

                                            {role === 'Super Admin' ? (
                                                <td className="text-center">
                                                    <Link className="border-0 bg-transparent" to={`/edit-user/${user.id}`}>
                                                        <i className="fas fa-pencil-alt nav-icon" style={{ color: "#CAAE76" }}></i>
                                                    </Link>
                                                    <button className="border-0 bg-transparent" onClick={() => deleteUser(user.id)}>
                                                        <i className="fas fa-trash-alt trash nav-icon text-danger"></i>
                                                    </button>
                                                </td>
                                            ) : (
                                                user.role_name === 'Manager' ? (
                                                    <td className="text-center">
                                                        <Link className="border-0 bg-transparent" to={`/edit-user/${user.id}`}>
                                                            <i className="fas fa-pencil-alt nav-icon" style={{ color: "#CAAE76" }}></i>
                                                        </Link>
                                                        <button className="border-0 bg-transparent" onClick={() => deleteUser(user.id)}>
                                                            <i className="fas fa-trash-alt trash nav-icon text-danger"></i>
                                                        </button>
                                                    </td>
                                                ) : (
                                                    <td className="text-center"></td>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center p-3">
                                <div>
                                    {total > per_page && (
                                    <Pagination
                                        activePage={current_page}
                                        totalItemsCount={total}
                                        itemsCountPerPage={per_page}
                                        onChange={handlePageChange}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        firstPageText='First'
                                        lastPageText='Last'
                                    />
                                    )}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
        )
    }

    return <main>{users.data && renderUserList()}</main>;
}

export default withAuthAdmin(Users);