import { useState } from "react";
import axios from "../../lib/axios";
import swal from "sweetalert";
import withAuthAdmin from "../../lib/withAuthAdmin";
import {useTranslation} from "react-i18next"

const CreateSettings = () => {
    const storeId = localStorage.getItem('store_id');

    const [setting, setSetting] = useState({
        store_id: '',
        prices_source: '',
        gold_ounce_price: '',
        silver_ounce_price: '',
        tax: '',
        email: '',
        cost_discount: '',
        increase_on_total: '',
        update_price_every: '',
        sar_to_usd_conversion: '',
        description_update: '',
    });

    const {t} = useTranslation()
    const platform = localStorage.getItem('platform')

    const handleInput = (e) => {
        e.persist();
        setSetting({...setting, [e.target.name]: e.target.value});
    }

    const submitSetting = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('store_id', storeId);
        formData.append('prices_source', setting.prices_source);
        formData.append('gold_ounce_price', setting.gold_ounce_price !== "" ? setting.gold_ounce_price : 0);
        formData.append('silver_ounce_price', setting.silver_ounce_price !== "" ? setting.silver_ounce_price : 0);
        formData.append('tax', setting.tax !== "" ? setting.tax : 0);
        formData.append('email', setting.email !== null ? setting.email : '');
        formData.append('cost_discount', setting.cost_discount !== "" ? setting.cost_discount : 0);
        formData.append('increase_on_total', setting.increase_on_total !== "" ? setting.increase_on_total : 0);
        formData.append('update_price_every', setting.update_price_every);
        formData.append('sar_to_usd_conversion', setting.sar_to_usd_conversion !== "" ? setting.sar_to_usd_conversion : 0);
        formData.append('description_update', setting.description_update);

        const settingsEndpoint = platform === 'Salla' ? 'settings' : 'zidSettings';
        axios.post(`/api/${settingsEndpoint}`, formData).then(res => {
            if (res.data.status === 200) {
                setSetting({...setting,
                    store_id: '',
                    prices_source: '',
                    gold_ounce_price: '',
                    silver_ounce_price: '',
                    tax: '',
                    email: '',
                    cost_discount: '',
                    increase_on_total: '',
                    update_price_every: '',
                    sar_to_usd_conversion: '',
                    description_update: '',
                });
                window.location.href = '/settings';
                swal("Success", `${t('layout.addedSuccessfully')}`, "success");
            } else if (res.data.status === 422) {
                swal("Error", `${t('layout.fieldsRequired')}`, "error");
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <>
            <div className="content-wrapper">
            <div className="second-content-wrapper my-4 py-4 rounded rounded-10" style={{ margin: "0 70px" }}>
                <div className='d-flex justify-content-center align-items-center px-4 py-3'>
                    <div className="mb-3">
                        <h3 style={{ fontSize: "19.04px" }}>{t('createSettings.title')}</h3>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <form onSubmit={submitSetting}>
                            <div className="card-body">
                                <div className="form-group">
                                    <div className="row d-flex align-items-center">
                                        <label className="col-md-3" htmlFor="prices_source"><span className="star-error">*</span> {t('settings.pricesSource')}</label>
                                        <select
                                            type="text" className="setting-input form-control col-md-8" id="prices_source"
                                            name="prices_source" value={setting.prices_source} onChange={handleInput}
                                        >
                                            <option></option>
                                            <option value="Gold Api">AutoGold Api</option>
                                            <option value="Manual">Manual</option>
                                        </select>
                                    </div>
                                </div>
                                {setting.prices_source === 'Manual' && (
                                    <div className="form-group">
                                        <div className="row d-flex align-items-center">
                                            <label className="col-md-3" htmlFor="gold_ounce_price">{t('settings.goldOuncePrice')}</label>
                                            <input
                                                type="number" className="setting-input form-control col-md-8" id="gold_ounce_price" placeholder={t('createSettings.enterGoldOuncePrice')}
                                                name="gold_ounce_price" value={setting.gold_ounce_price} onChange={handleInput}
                                            />
                                        </div>
                                    </div>
                                )}
                                {setting.prices_source === 'Manual' && (
                                    <div className="form-group">
                                        <div className="row d-flex align-items-center">
                                            <label className="col-md-3" htmlFor="silver_ounce_price">{t('settings.silverOuncePrice')}</label>
                                            <input
                                                type="number" className="setting-input form-control col-md-8" id="silver_ounce_price" placeholder={t('createSettings.enterSilverOuncePrice')}
                                                name="silver_ounce_price" value={setting.silver_ounce_price} onChange={handleInput}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="form-group">
                                    <div className="row d-flex align-items-center">
                                        <label className="col-md-3" htmlFor="tax">{t('settings.tax')}</label>
                                        <input
                                            type="number" className="setting-input form-control col-md-8" id="tax" placeholder={t('createSettings.enterTax')}
                                            name="tax" value={setting.tax} onChange={handleInput}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row d-flex align-items-center">
                                        <label className="col-md-3" htmlFor="email">{t('settings.email')}</label>
                                        <input
                                            type="email" className="setting-input form-control col-md-8" id="email" disabled
                                            placeholder={t('createSettings.enterEmail')}
                                            name="email" value={localStorage.getItem('auth_email')} onChange={handleInput}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row d-flex align-items-center">
                                        <label className="col-md-3" htmlFor="cost_discount">{t('settings.costDiscount')}</label>
                                        <input
                                            type="number" className="setting-input form-control col-md-8" id="cost_discount" placeholder={t('createSettings.enterCostDiscount')}
                                            name="cost_discount" value={setting.cost_discount} onChange={handleInput}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row d-flex align-items-center">    
                                        <label className="col-md-3" htmlFor="increase_on_total">{t('settings.increaseOnTotal')}</label>
                                        <input
                                            type="number" className="setting-input form-control col-md-8" id="increase_on_total" placeholder={t('createSettings.enterIncreaseOnTotal')}
                                            name="increase_on_total" value={setting.increase_on_total} onChange={handleInput}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row d-flex align-items-center">    
                                        <label className="col-md-3" htmlFor="update_price_every"><span className="star-error">*</span> {t('settings.updatePriceEvery')}</label>
                                        <select
                                            type="text" className="setting-input form-control col-md-8" id="update_price_every" placeholder="Update price every"
                                            name="update_price_every" value={setting.update_price_every} onChange={handleInput}
                                        >
                                            <option>{t('createSettings.chooseAnOption')}</option>
                                            <option value="15 Minute">15 Minute</option>
                                            <option value="30 Minute">30 Minute</option>
                                            <option value="One Hour">One Hour</option>
                                            <option value="6 Hours">6 Hours</option>
                                            <option value="12 Hours">12 Hours</option>
                                            <option value="24 Hours">24 Hours</option>
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="form-group">
                                    <div className="row d-flex align-items-center">   
                                        <label className="col-md-3" htmlFor="sar_to_usd_conversion">{t('settings.sarToUsdConversion')}</label>
                                        <input
                                            type="number" className="setting-input form-control col-md-8" id="sar_to_usd_conversion" placeholder={t('createSettings.enterSAR')}
                                            name="sar_to_usd_conversion" value={setting.sar_to_usd_conversion} onChange={handleInput}
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="form-group">
                                    <div className="row d-flex align-items-center">    
                                        <label className="col-md-3" htmlFor="description_update">{t('settings.descriptionUpdate')}</label>
                                        <select
                                            type="text" className="setting-input form-control col-md-8" id="description_update" placeholder="Description Update"
                                            name="description_update" value={setting.description_update} onChange={handleInput}
                                        >
                                            <option>{t('createSettings.chooseAnOption')}</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div> */}
                                <button type="submit" className="btn text-white" style={{ background: "#CAAE76" }}>{t('createSettings.title')}</button>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
            </div>
        </>
    )
}

export default withAuthAdmin(CreateSettings)