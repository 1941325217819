import PlansImg from "../../imgs/plans.png"
import BankTransfer from "../../imgs/bank-transfer.png"
import { useTranslation } from "react-i18next";
import { useState } from "react";
import swal from "sweetalert";
import axios from "../../lib/axios";

const ComprehensivePackage = () => {
    const { t } = useTranslation();
    const subscriptionDuration = localStorage.getItem("subscription_duration");
    const [selectedFile, setSelectedFile] = useState(null);

    const [subscriptionType, setSubscriptionType] = useState(
        localStorage.getItem('subscription_duration') || 'annualSubscription'
    );
    
    const handleSubscriptionChange = (event) => {
        const selectedValue = event.target.value;
        localStorage.setItem('subscription_duration', selectedValue);
        setSubscriptionType(selectedValue);
    };

    const handleFileEmportChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleImageUpload = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('image', selectedFile);
    
            axios.post("/api/sendImageTransfer", formData)
                .then(response => {
                    swal("Success", `${t('comprehensivePackage.transferSentSuccessfully')}`, "success");
                    setSelectedFile(null)
                })
                .catch(error => {
                    console.error("Error sending image:", error);
                });
        } else {
            console.error("No file selected");
        }
    };

    return (
        <div className="content-wrapper comprehensive-package">
            <div className="second-content-wrapper m-4 py-4 rounded rounded-10">
                <div className="d-flex align-items-center justify-content-center pb-4">
                    <div className="page-title d-flex align-items-center justify-content-between">
                        <img src={PlansImg} alt="Plans" width={42} height={42} />
                        <h1 style={{ fontSize: "24px", color: "#000000", margin: 0 }}>{t('comprehensivePackage.comprehensivePackage')}</h1>
                    </div>
                </div>
                <div class="mx-4 package-informations-box">
                    <div class="package-informations">
                        <div class="package-informations-item">
                            <div class="item-key">{t('comprehensivePackage.taxNumber')}:</div>
                            <div class="item-value">582121423023500</div>
                        </div>
                    </div>
                    <div class="package-informations">
                        <div class="package-informations-item">
                            <div class="item-key">{t('comprehensivePackage.subscriptionDuration')}:</div>
                            <select class="item-value" value={subscriptionType} onChange={handleSubscriptionChange}>
                                <option value="annualSubscription">{t('comprehensivePackage.annualSubscription')}</option>
                                <option value="monthlySubscription">{t('comprehensivePackage.monthlySubscription')}</option>
                            </select>
                        </div>
                    </div>
                    <div class="package-informations">
                        <div class="package-informations-item">
                            <div class="item-key">{t('comprehensivePackage.originalPackagePrice')}:</div>
                            <div className="item-value">
                                {subscriptionDuration === "annualSubscription" ? (
                                    <span>2388</span>
                                ) : (
                                    <span>59</span>
                                )} {t('comprehensivePackage.sar')}
                            </div>
                        </div>
                    </div>
                    <div class="package-informations">
                        <div class="package-informations-item">
                            <div class="item-key">{t('comprehensivePackage.tax')}:</div>
                            <div className="item-value">
                                {subscriptionDuration === "annualSubscription" ? (
                                    <span>420</span>
                                ) : (
                                    <span>39</span>
                                )} {t('comprehensivePackage.sar')}
                            </div>
                        </div>
                    </div>
                    <div class="package-informations">
                        <div class="package-informations-item">
                            <div class="item-key">{t('comprehensivePackage.totalPrice')}:</div>
                            <div className="item-value">
                                {subscriptionDuration === "annualSubscription" ? (
                                    <span>2808</span>
                                ) : (
                                    <span>98</span>
                                )} {t('comprehensivePackage.sar')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-5 py-3" style={{ width: "100%" }}>
                    <h2 className="text-center" style={{ fontSize: "20px", color: "#000000", margin: 0 }}>{t('comprehensivePackage.paymentMethod')}</h2>
                    <div className="mx-auto" style={{ width: 150 }}>
                        <img src={BankTransfer} alt="Bank Transfer" />
                    </div>
                    <h2 className="text-center" style={{ fontSize: "18px", color: "#BF9725", marginTop: -24 }}>{t('comprehensivePackage.bankTransfer')}</h2>
                </div>
                <div className="mb-5">
                    <h3 className="mx-4" style={{ fontSize: "20px", color: "#323232" }}>{t('comprehensivePackage.accountInformation')}</h3>
                    <div class="mx-4 package-informations-box">
                        <div class="package-informations">
                            <div class="package-informations-item">
                                <div class="item-key">{t('comprehensivePackage.accountHolderName')}:</div>
                                <div class="item-value">{t('comprehensivePackage.lavana')}</div>
                            </div>
                        </div>
                        <div class="package-informations">
                            <div class="package-informations-item">
                                <div class="item-key">{t('comprehensivePackage.bank')}:</div>
                                <div class="item-value">{t('comprehensivePackage.riyadBank')}</div>
                            </div>
                        </div>
                        <div class="package-informations">
                            <div class="package-informations-item">
                                <div class="item-key">{t('comprehensivePackage.accountNumber')}:</div>
                                <div class="item-value">1574387529940 </div>
                            </div>
                        </div>
                        <div class="package-informations">
                            <div class="package-informations-item">
                                <div class="item-key">{t('comprehensivePackage.iban')}:</div>
                                <div class="item-value">SA8520000001574387529940 </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy-transfer mx-4">
                    <h3 className="" style={{ fontSize: "20px", color: "#323232" }}>{t('comprehensivePackage.copyTransfer')}</h3>
                    <div className="d-flex import">
                        <input
                            type="file"
                            id="fileInput"
                            className="file-input"
                            onChange={handleFileEmportChange}
                        />
                        <button className="choose-button"  onClick={() => document.getElementById('fileInput').click()}>
                            {t('importAndExport.selectFile')}   
                        </button>
                        <div className="file-display d-flex align-items-center">
                            {selectedFile ? selectedFile.name : `${t('importAndExport.noFileSelected')}` }
                        </div>
                        <button className="import-button" onClick={handleImageUpload} disabled={!selectedFile}>
                            <span>{t('comprehensivePackage.attach')}</span> 
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComprehensivePackage