import Goldimg from '../../imgs/goldimg.png'
import Silverimg from '../../imgs/silverimg.png'
import {useTranslation} from "react-i18next"

const GoldSilverPrice = () => {
    const {t} = useTranslation()

    return (
        <>
            <div className="d-none d-lg-block">
                <div className="card-gold py-4 mt-4" style={{ width: "100%" }}>
                    <div className='d-flex justify-content-center align-items-center mx-auto'>
                        <img src={Goldimg} alt="Gold" />
                        <h2 className="text-center mr-2" style={{ fontSize: "20px" }}>{t('layoutProducts.goldFinalPrice')}</h2>
                    </div>
                    <div className='equation mx-auto py-2 mt-4'>
                        <p className='text-center'>
                            <span className='text-equation'>({t('layoutProducts.costwithDiscount')}</span><span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.goldPrice')})</span>
                            <span className='math-equation'>x</span><span className='text-equation'>{t('layoutProducts.weight')}</span><span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.fixedCost')}</span>
                            <span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.percentageIncrease')}</span><span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.tax')}</span>
                        </p>
                    </div>
                </div>
                <div className="card-gold pt-2 pb-4 mt-4 mb-4" style={{ width: "100%" }}>
                    <div className='d-flex justify-content-center align-items-center mx-auto'>
                        <img src={Silverimg} alt="Silver" />
                        <h2 className="text-center mr-2" style={{ fontSize: "20px" }}>{t('layoutProducts.silverFinalPrice')}</h2>
                    </div>
                    <div className='equation mx-auto py-2 mt-4'>
                        <p className='text-center'>
                            <span className='text-equation'>({t('layoutProducts.costwithDiscount')}</span><span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.silverPrice')})</span>
                            <span className='math-equation'>x</span><span className='text-equation'>{t('layoutProducts.weight')}</span><span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.fixedCost')}</span>
                            <span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.percentageIncrease')}</span><span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.tax')}</span>                    
                        </p>
                    </div>
                </div>
            </div>

            <div className="d-block d-lg-none">
                <div className="card-gold py-4 mt-4" style={{ width: "100%" }}>
                    <div className='d-flex justify-content-center align-items-center mx-auto'>
                        <img src={Goldimg} alt="Gold" />
                        <h2 className="mr-2" style={{ fontSize: "20px" }}>{t('layoutProducts.goldFinalPrice')}</h2>
                    </div>
                    <div className='equation mx-auto py-2 mt-4'>
                        <p className='text-center'>
                            <span className='text-equation'>({t('layoutProducts.costwithDiscount')}</span><span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.goldPrice')})</span>
                            <span className='math-equation'>x</span><span className='text-equation'>{t('layoutProducts.weight')}</span><span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.fixedCost')}</span>
                            <span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.percentageIncrease')}</span><span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.tax')}</span>
                        </p>
                    </div>
                </div>
                <div className="card-gold pt-2 pb-4 mt-4 mb-4" style={{ width: "100%" }}>
                    <div className='d-flex justify-content-center align-items-center mx-auto'>
                        <img src={Silverimg} alt="Silver" />
                        <h2 className="mr-2" style={{ fontSize: "20px" }}>{t('layoutProducts.silverFinalPrice')}</h2>
                    </div>
                    <div className='equation mx-auto py-2 mt-3'>
                        <p className='text-center'>
                            <span className='text-equation'>({t('layoutProducts.costwithDiscount')}</span><span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.silverPrice')})</span>
                            <span className='math-equation'>x</span><span className='text-equation'>{t('layoutProducts.weight')}</span><span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.fixedCost')}</span>
                            <span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.percentageIncrease')}</span><span className='math-equation'>+</span><span className='text-equation'>{t('layoutProducts.tax')}</span>                    
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GoldSilverPrice