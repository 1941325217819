import { useTranslation } from "react-i18next"
import PlansImg from "../../imgs/plans.png"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "../../lib/axios"
import withAuthAdmin from "../../lib/withAuthAdmin"

const Plans = () => {
    const {t} = useTranslation()

    const [planInformations, setPlanInformations] = useState({});
    const storedStoreId = localStorage.getItem('store_id');
    const platform = localStorage.getItem('platform')

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            const planEndpoint = platform === 'Salla' ? 'plan' : 'zidPlan';
            const planInformations = await axios.get(`/api/${planEndpoint}?store_id=${storedStoreId}`);

            setPlanInformations(planInformations.data.planInformations);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="content-wrapper plans">
            <div className="title-and-plans second-content-wrapper m-4 py-4 rounded rounded-10">
                <div className="d-flex align-items-center justify-content-center pb-4">
                    <div className="page-title d-flex align-items-center justify-content-between">
                        <img src={PlansImg} alt="Plans" width={42} height={42} />
                        <h1 style={{ fontSize: "24px", color: "#000000", margin: 0 }}>{t('plans.packages')}</h1>
                    </div>
                </div>
                <div className="plans-box mx-auto">
                    <div className="plans-box2">
                        <div className="plan">
                            <div className="d-flex align-items-center justify-content-center" style={{width: "100%", height: "24%", background: 'rgba(202, 174, 118, 0.15)', borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                                <h2 style={{color: 'black', fontSize: 20, fontWeight: '600', wordWrap: 'break-word'}}>{t('plans.freePackage')}</h2>
                            </div>
                            <div style={{height: "76%"}}>
                                <div className="d-flex flex-column align-items-center justify-content-between py-3" style={{height: "100%"}}>
                                    <h3 style={{color: '#707070', fontSize: 20, fontWeight: '400', wordWrap: 'break-word'}}>{t('plans.upToProducts')}</h3>
                                    <h3 style={{color: '#CAAE76', fontSize: 50, fontWeight: '400', wordWrap: 'break-word'}}>{t('plans.free')}</h3>
                                    <h3 style={{color: 'black', fontSize: 20, fontWeight: '400', wordWrap: 'break-word'}}>
                                        {planInformations.plan !== "All inclusive package monthly" ? (
                                            <>
                                                {t('plans.currentPackage')}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="plan">
                            <div className="d-flex align-items-center justify-content-center" style={{width: "100%", height: "24%", background: 'rgba(202, 174, 118, 0.15)', borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                                <h2 style={{color: 'black', fontSize: 20, fontWeight: '600', wordWrap: 'break-word'}}>{t('plans.comprehensivePackage')}</h2>
                            </div>
                            <div style={{height: "76%"}}>
                                <div className="d-flex flex-column align-items-center justify-content-between py-3" style={{height: "100%"}}>
                                    <h3 style={{color: '#707070', fontSize: 20, fontWeight: '400', wordWrap: 'break-word'}}>{t('plans.infiniteProducts')}</h3>
                                    <h3 style={{ color: '#CAAE76' }}><span style={{fontSize: 90, fontWeight: '400', wordWrap: 'break-word'}}>59</span><span>{t('plans.sar')}</span></h3>
                                    {planInformations.plan !== "All inclusive package monthly" ? (
                                        <Link to="/comprehensive-package" className="btn border border-none rounded" style={{ color: '#FFFFFF', background: "#CAAE76", width: "52%", height: "46px", fontSize: "18px" }}>{t('plans.promotion')}</Link>
                                    ) : (
                                        <h3 style={{color: 'black', fontSize: 20, fontWeight: '400', wordWrap: 'break-word'}}>{t('plans.currentPackage')}</h3>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withAuthAdmin(Plans)