import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const CostChart = (props) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (props.costDiscount !== 0) {
        const ctx = chartRef.current.getContext('2d');
        const data = {
            datasets: [
            {
                data: [props.costDiscount, 100 - props.costDiscount],
                backgroundColor: ['#CAAE76', '#EEEEEE'],
            },
            ],
        };
        const options = {
            responsive: true,
            maintainAspectRatio: false,
        };

        new Chart(ctx, {
            type: 'doughnut',
            data,
            options,
        });
        }
    }, [props.costDiscount]);

    return (
        <div className='mb-3'>
            <canvas ref={chartRef} width="151" />
        </div>
    );
};

export default CostChart;
