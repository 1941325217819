import { useEffect, useState } from "react";
import withAuth from "../lib/withAuth"
import axios from "../lib/axios";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import {useTranslation} from "react-i18next"
import Dashboard from '../imgs/dashboard.png'
import AllProducts from '../imgs/allProducts.png'
import CompletedProducts from '../imgs/completedProducts.png'
import DeletedProducts from '../imgs/deletedProducts.png'
import Notifications from '../imgs/notifications.png'
import FoundationDollar from '../imgs/foundation_dollar.png'
import ClarityDateSolid from '../imgs/clarity_date-solid.png'
import IcSharpUpdate from '../imgs/ic_sharp-update.png'
import RenewalPrice from '../imgs/renewalprice.png'

const itemsPerPage = 1;
const totalItems = 4;

function Home() {
    const [allProductsNumber, setAllProductsNumber] = useState(0);
    const [completedProductsNumber, setCompletedProductsNumber] = useState(0);
    const [deletedProductsNumber, setDeletedProductsNumber] = useState(0);
    const [notificationsNumber, setNotificationsNumber] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [planInformations, setPlanInformations] = useState({});
    const [activePage, setActivePage] = useState(1);
    const {t} = useTranslation()
    const storedStoreId = localStorage.getItem('store_id');
    const role = localStorage.getItem('auth_role')
    const platform = localStorage.getItem('platform')

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        getCountriesData(activePage);
    }, [activePage]);

    async function fetchData() {
        try {
            const statisticsEndpoint = platform === 'Salla' ? 'statistics' : 'zidStatistics';
            const statistics = await axios.get(`/api/${statisticsEndpoint}?store_id=${storedStoreId}`);

            const planEndpoint = platform === 'Salla' ? 'plan' : 'zidPlan';
            const planInformations = await axios.get(`/api/${planEndpoint}?store_id=${storedStoreId}`);

            setAllProductsNumber(statistics.data.allProductsNumber);
            setCompletedProductsNumber(statistics.data.completedProductsNumber);
            setDeletedProductsNumber(statistics.data.deletedProductsNumber);
            setNotificationsNumber(statistics.data.notificationsNumber);
            setPlanInformations(planInformations.data.planInformations);
        } catch (error) {
            console.error(error);
            
        }
    }

    async function getCountriesData(pageNumber = 1) {
        try {
            const notificationsEndpoint = platform === 'Salla' ? 'notifications' : 'zidNotifications';
            const url = `/api/${notificationsEndpoint}?page=${pageNumber}&store_id=${storedStoreId}`;
            const response = await axios.get(url);
            setNotifications(response.data.notifications);
        } catch (error) {
            console.error(error);
            
        }
    }

    async function deleteNotification(id) {
        try {
            const deleteNotificationsEndpoint = platform === 'Salla' ? 'notifications' : 'zidNotifications';
            const response = await axios.delete(`/api/${deleteNotificationsEndpoint}/${id}`);
            console.log(response.data.message);
            getCountriesData(activePage);
            swal("Success", 'Delete successfully', "success");
        } catch (error) {
            console.error(error.response.data.message);
            
        }
    }

    const [products, setProducts] = useState(false);
    const mouseEnterProducts = () => {
        setProducts(true);
    };
    const mouseLeaveProducts = () => {
        setProducts(false);
    };

    const [productsCompl, setProductsCompl] = useState(false);
    const mouseEnterCompl = () => {
        setProductsCompl(true);
    };
    const mouseLeaveCompl = () => {
        setProductsCompl(false);
    };

    const [productsDelet, setProductsDelet] = useState(false);
    const mouseEnterDelet = () => {
        setProductsDelet(true);
    };
    const mouseLeaveDelet = () => {
        setProductsDelet(false);
    };

    const [notific, setNotific] = useState(false);
    const mouseEnterNotific = () => {
        setNotific(true);
    };
    const mouseLeaveNotific = () => {
        setNotific(false);
    };

    const [activeBox, setActiveBox] = useState(1);
    const startIndex = (activeBox - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const items = [];

    const itemData = [
        {
            title: t('home.products'),
            imageSrc: AllProducts,
            number: allProductsNumber,
            onMouseEnter: mouseEnterProducts,
            onMouseLeave: mouseLeaveProducts,
            products: products,
        },
        {
            title: t('home.completedProducts'),
            imageSrc: CompletedProducts,
            number: completedProductsNumber,
            onMouseEnter: mouseEnterCompl,
            onMouseLeave: mouseLeaveCompl,
            products: productsCompl,
        },
        {
            title: t('home.deletedProducts'),
            imageSrc: DeletedProducts,
            number: deletedProductsNumber,
            onMouseEnter: mouseEnterDelet,
            onMouseLeave: mouseLeaveDelet,
            products: productsDelet,
        },
        {
            title: t('home.notifications'),
            imageSrc: Notifications,
            number: notificationsNumber,
            onMouseEnter: mouseEnterNotific,
            onMouseLeave: mouseLeaveNotific,
            products: notific,
        },
    ];

    for (let i = startIndex; i < endIndex; i++) {
        const item = itemData[i];
        items.push(
        <div key={i} className="item">
            <div style={{ paddingBottom: "12px", background: "#FFFFFF", boxShadow: "0px 4.525312900543213px 45.253143310546875px 0px #0000001A", borderRadius: "11.31px" }}>
            <div className="d-flex align-items-center justify-content-between px-4 pt-3">
                <h2 style={{ fontSize: "19.04px" }}>{item.title}</h2>
                <img src={item.imageSrc} alt={item.title} width={34} height={34.69} />
            </div>
            <div className="px-4">
                <h2 style={{ fontSize: "67.88px" }}>{item.number}</h2>
            </div>
            <hr className="mb-5" />
            <div
                className="hover-popup m-auto"
                style={{
                width: "85%",
                height: "13.97px",
                borderRadius: "56.57px",
                background: "#CAAE76",
                }}
                onMouseEnter={item.onMouseEnter}
                onMouseLeave={item.onMouseLeave}
            >
                {item.products && (
                <>
                    <div
                    className="popup active"
                    style={{
                        width: "33.94px",
                        height: "23.28px",
                        background: "#FFFFFF",
                        boxShadow: "0px 4.525312900543213px 11.313285827636719px 0px #00000026",
                        position: "relative",
                        bottom: "230%",
                        right: "45%",
                    }}
                    >
                    <span>{item.number}</span>
                    </div>
                    <div className="arrow m-auto"></div>
                </>
                )}
            </div>
            <div className="d-flex align-items-center justify-content-between px-4">
                <span style={{ fontSize: "15.84px" }}>0</span>
                <span style={{ fontSize: "10.45px" }}>{item.title}</span>
                <span style={{ fontSize: "15.84px" }}>100</span>
            </div>
            </div>
        </div>
        );
    }

    const handlePageChange = (pageNumber) => {
        setActiveBox(pageNumber);
    };

    function renderNotificationList() {
        const { data, current_page, per_page, total } = notifications;
        return (
            <>
                {role !== 'Manager' ? (
                    <div className="d-none d-lg-block" style={{ width: "67%" }}>
                        <div>
                            <div className="mb-3">
                                <h3 style={{ fontSize: "19.04px" }}>{t('home.notifications')}</h3>
                            </div>
                            <div style={{ background: "#FFFFFF", boxShadow: "0px 4px 40px 0px #0000001A"}}>
                                <div className="card-body table-responsive p-0" style={{ boxhadow: "0px 4.525312900543213px 45.253143310546875px 0px #0000001A" }}>
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>#{t('home.id')}</th>
                                                <th>{t('home.type')}</th>
                                                <th>{t('home.HighOrLow')}</th>
                                                <th>{t('home.percentage')}</th>
                                                <th>{t('home.comparison')}</th>
                                                <th>{t('home.action')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((notification) => (
                                                <tr key={notification.id}>
                                                    <td style={{ color: "#909090" }}>{notification.id}</td>
                                                    <td style={{ color: "#CAAE76" }}>{notification.type}</td>
                                                    <td style={{ color: "#909090" }}>{notification.increase_or_decrease}</td>
                                                    <td style={{ color: "#CAAE76" }}>{notification.percentage}</td>
                                                    <td style={{ color: "#909090" }}>{notification.compare_by_days}</td>
                                                    <td>
                                                    <Link className="border-0 bg-transparent" to={`/edit-notification/${notification.id}`}>
                                                        <i className="fas fa-pencil-alt nav-icon" style={{ color: "#CAAE76" }}></i>
                                                    </Link>
                                                    <button className="border-0 bg-transparent" onClick={() => deleteNotification(notification.id)} style={{ margin: "10px" }}>
                                                        <i className="fas fa-trash-alt nav-icon text-danger"></i>
                                                    </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex align-items-center justify-content-center p-3">
                                    <div>
                                        {total > per_page && (
                                            <Pagination
                                                activePage={current_page}
                                                totalItemsCount={total}
                                                itemsCountPerPage={per_page}
                                                onChange={setActivePage}
                                                itemClass='page-item'
                                                linkClass='page-link'
                                                firstPageText='First'
                                                lastPageText='Last'
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </>
        )
    }
    
    return <main>
                <div className="content-wrapper" style={{ background: "#FFFFFF" }}>
                    <div className="second-content-wrapper m-4 py-4 rounded rounded-10">
                        <div className="d-flex align-items-center justify-content-center pb-4">
                            <div className="page-title d-flex align-items-center">
                                <img src={Dashboard} alt="dashboard" width={18} height={18} />
                                <h1 style={{ fontSize: "24px", color: "#000000" }}>{t('home.title')}</h1>
                            </div>
                        </div>
                        <section className="content mx-4">
                            <div className="container-fluid">
                                <div className="product-statistics-mobile" style={{ marginBottom: "-50px" }}>
                                    {items}
                                    <div className="d-flex justify-content-center mt-4">
                                        <Pagination
                                            activePage={activeBox}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={totalItems}
                                            pageRangeDisplayed={totalItems}
                                            onChange={handlePageChange}
                                            itemClass='page-item'
                                            linkClass='page-link'
                                        />
                                    </div>
                                </div>

                                <div className="product-statistics">
                                    <div className="statistics-two-items">
                                        <div className="product-statistics-items">
                                            <Link to="/all-products" style={{ textDecoration: "none", color: "#323232" }}>
                                                <div style={{ paddingBottom: "12px", background: "#FFFFFF", boxShadow: "0px 4.525312900543213px 45.253143310546875px 0px #0000001A", borderRadius: "11.31px" }}>
                                                    <div className="d-flex align-items-center justify-content-between px-4 pt-3">
                                                        <h2 className="title">{t('home.products')}</h2>
                                                        <img className="img-icon" src={AllProducts} alt="All products" />
                                                    </div>
                                                    <div className="px-4">
                                                        <h2 className="number">{allProductsNumber}</h2>
                                                    </div>
                                                    <hr className="mb-5" />
                                                    <div
                                                        className="hover-popup m-auto"
                                                        style={{
                                                        width: "85%",
                                                        height: "13.97px",
                                                        borderRadius: "56.57px",
                                                        background: "#CAAE76",
                                                        }}
                                                        onMouseEnter={mouseEnterProducts}
                                                        onMouseLeave={mouseLeaveProducts}
                                                    >
                                                        {products && (
                                                        <>
                                                            <div className="popup active">
                                                                <span>{allProductsNumber}</span>
                                                            </div>
                                                            <div className="arrow m-auto"></div>
                                                        </>
                                                        )}
                                                    </div>
                                                    <div className="chart d-flex align-items-center justify-content-between px-4">
                                                        <span>0</span>
                                                        <span>{t('home.products')}</span>
                                                        <span>100</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="product-statistics-items">
                                            <Link to="/completed-products" style={{ textDecoration: "none", color: "#323232" }}>
                                                <div style={{ paddingBottom: "12px", background: "#FFFFFF", boxShadow: "0px 4.525312900543213px 45.253143310546875px 0px #0000001A", borderRadius: "11.31px" }}>
                                                    <div className="d-flex align-items-center justify-content-between px-4 pt-3">
                                                        <h2 className="title">{t('home.completedProducts')}</h2>
                                                        <img className="img-icon" src={CompletedProducts} alt="All products" />
                                                    </div>
                                                    <div className="px-4">
                                                        <h2 className="number">{completedProductsNumber}</h2>
                                                    </div>
                                                    <hr className="mb-5" />
                                                    <div
                                                        className="hover-popup m-auto"
                                                        style={{
                                                        width: "85%",
                                                        height: "13.97px",
                                                        borderRadius: "56.57px",
                                                        background: "#CAAE76",
                                                        }}
                                                        onMouseEnter={mouseEnterCompl}
                                                        onMouseLeave={mouseLeaveCompl}
                                                    >
                                                        {productsCompl && (
                                                        <>
                                                            <div className="popup active">
                                                                <span>{completedProductsNumber}</span>
                                                            </div>
                                                            <div className="arrow m-auto"></div>
                                                        </>
                                                        )}
                                                    </div>
                                                    <div className="chart d-flex align-items-center justify-content-between px-4">
                                                        <span>0</span>
                                                        <span>{t('home.completedProducts')}</span>
                                                        <span>100</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="statistics-two-items">
                                        <div className="product-statistics-items">
                                            <Link to="/deleted-products" style={{ textDecoration: "none", color: "#323232" }}>
                                                <div style={{ paddingBottom: "12px", background: "#FFFFFF", boxShadow: "0px 4.525312900543213px 45.253143310546875px 0px #0000001A", borderRadius: "11.31px" }}>
                                                    <div className="d-flex align-items-center justify-content-between px-4 pt-3">
                                                        <h2 className="title">{t('home.deletedProducts')}</h2>
                                                        <img className="img-icon" src={DeletedProducts} alt="All products" />
                                                    </div>
                                                    <div className="px-4">
                                                        <h2 className="number">{deletedProductsNumber}</h2>
                                                    </div>
                                                    <hr className="mb-5" />
                                                    <div
                                                        className="hover-popup m-auto"
                                                        style={{
                                                        width: "85%",
                                                        height: "13.97px",
                                                        borderRadius: "56.57px",
                                                        background: "#CAAE76",
                                                        }}
                                                        onMouseEnter={mouseEnterDelet}
                                                        onMouseLeave={mouseLeaveDelet}
                                                    >
                                                        {productsDelet && (
                                                        <>
                                                            <div className="popup active">
                                                                <span>{deletedProductsNumber}</span>
                                                            </div>
                                                            <div className="arrow m-auto"></div>
                                                        </>
                                                        )}
                                                    </div>
                                                    <div className="chart d-flex align-items-center justify-content-between px-4">
                                                        <span>0</span>
                                                        <span>{t('home.deletedProducts')}</span>
                                                        <span>100</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="product-statistics-items">
                                            <Link to="/notifications" style={{ textDecoration: "none", color: "#323232" }}>
                                                <div style={{ paddingBottom: "12px", background: "#FFFFFF", boxShadow: "0px 4.525312900543213px 45.253143310546875px 0px #0000001A", borderRadius: "11.31px" }}>
                                                    <div className="d-flex align-items-center justify-content-between px-4 pt-3">
                                                        <h2 className="title">{t('home.notifications')}</h2>
                                                        <img className="img-icon" src={Notifications} alt="All products" />
                                                    </div>
                                                    <div className="px-4">
                                                        <h2 className="number">{notificationsNumber}</h2>
                                                    </div>
                                                    <hr className="mb-5" />
                                                    <div
                                                        className="hover-popup m-auto"
                                                        style={{
                                                        width: "85%",
                                                        height: "13.97px",
                                                        borderRadius: "56.57px",
                                                        background: "#CAAE76",
                                                        }}
                                                        onMouseEnter={mouseEnterNotific}
                                                        onMouseLeave={mouseLeaveNotific}
                                                    >
                                                        {notific && (
                                                        <>
                                                            <div className="popup active">
                                                                <span>{notificationsNumber}</span>
                                                            </div>
                                                            <div className="arrow m-auto"></div>
                                                        </>
                                                        )}
                                                    </div>
                                                    <div className="chart d-flex align-items-center justify-content-between px-4">
                                                        <span>0</span>
                                                        <span>{t('home.notifications')}</span>
                                                        <span>100</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-5">
                                    {notifications.data && renderNotificationList()}
                                    <div className="subscription-information py-5">
                                        <h3 className="text-center" style={{ fontSize: "19.04px" }}>{t('home.subscriptionInformation')}</h3>
                                        <div className="d-flex justify-content-between align-items-center mx-auto my-3 px-4 py-2" style={{ width: "88%" ,background: "#FFFFFF", borderRadius: "15px" }}>
                                            <div>
                                                <h4 className="title">{t('home.packageName')}</h4>
                                                <h4 className="type">{planInformations.plan}</h4>
                                            </div>
                                            <div className="box-img d-flex justify-content-center align-items-center">
                                                <img src={FoundationDollar} alt="Foundation dollar" />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mx-auto my-3 px-4 py-2" style={{ width: "88%" ,background: "#FFFFFF", borderRadius: "15px" }}>
                                            <div>
                                                <h4 className="title">{t('home.subscriptionDate')}</h4>
                                                <h4 className="type">{planInformations.start_date}</h4>
                                            </div>
                                            <div className="box-img d-flex justify-content-center align-items-center">
                                                <img src={ClarityDateSolid} alt="Foundation dollar" />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mx-auto my-3 px-4 py-2" style={{ width: "88%" ,background: "#FFFFFF", borderRadius: "15px" }}>
                                            <div>
                                                <h4 className="title">{t('home.expireDate')}</h4>
                                                <h4 className="type">{planInformations.end_date}</h4>
                                            </div>
                                            <div className="box-img d-flex justify-content-center align-items-center">
                                                <img src={IcSharpUpdate} alt="Foundation dollar" />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mx-auto my-3 px-4 py-2" style={{ width: "88%" ,background: "#FFFFFF", borderRadius: "15px" }}>
                                            <div>
                                                <h4 className="title">{t('home.renewalPrice')}</h4>
                                                <h4 className="type">99 {t('home.saudiRiyals')}</h4>
                                            </div>
                                            <div className="box-img d-flex justify-content-center align-items-center">
                                                <img src={RenewalPrice} alt="Foundation dollar" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </main>;
}

export default withAuth(Home)