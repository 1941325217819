import { Link } from "react-router-dom"
import withAuthAdmin from "../../lib/withAuthAdmin";
import { useCallback, useEffect, useState } from "react";
import axios from "../../lib/axios";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import {useTranslation} from "react-i18next"
import gridiconsAdd from '../../imgs/gridicons_add.png'

function Roles() {
    const [roles, setRoles] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [perPage, setPerPage] = useState(10);
    const {t} = useTranslation()

    useEffect(() => {
        getRolesData(activePage, perPage);
    }, [activePage, perPage]);

    async function getRolesData(pageNumber, pageSize) {
        try {
        const response = await axios.get(`/api/roles?page=${pageNumber}&per_page=${pageSize}`);
        setRoles(response.data.roles);
        } catch (error) {
        console.error(error);
        }
    }

    const handleSearch = useCallback(async () => {
        try {
            const response = await axios.get('/api/searchRoles', {
                params: { searchTerm },
            });
    
            setRoles(response.data.roles);
        } catch (error) {
            console.error(error);
        }
    }, [searchTerm]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleSearch();
        }, 500);
    
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, handleSearch]);

    async function deleteRole(id) {
        try {
        const response = await axios.delete(`/api/roles/${id}`);
        console.log(response.data.message);
        getRolesData(activePage, perPage);
        swal("Success", `${t('layout.deleteSuccessfully')}`, "success");
        } catch (error) {
        console.error(error.response.data.message);
        }
    }

    function handlePageChange(pageNumber) {
        setActivePage(pageNumber);
    }

    function handlePerPageChange(event) {
        setPerPage(parseInt(event.target.value));
        setActivePage(1);
    }

    function renderRoleList() {
        const { data, current_page, per_page, total } = roles;
        return (
            <>
                <div className="content-wrapper">
                    <div className="second-content-wrapper m-4 py-4 rounded rounded-10">
                        <div className='d-flex justify-content-between align-items-center px-4 py-3'>
                            <div className="mb-3">
                                <h3 style={{ fontSize: "19.04px" }}>{t('roles.title')}</h3>
                            </div>
                            <Link to="/create-Role" className="add-product-btn border-0 btn">
                                <img src={gridiconsAdd} alt="Add roles" />
                                {t('roles.add')}
                            </Link>
                        </div>

                        <div className="d-none d-lg-block">
                            <div className='d-flex justify-content-between align-items-center px-4 py-3'>
                                <form onSubmit={handleSearch}>
                                    <div className="input-group" style={{ width: "300px", height: "40px" }}>
                                        <input 
                                            type="search" className="form-control form-control-lg" placeholder={t('roles.search')}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <button type='button' className="btn btn-lg btn-default" style={{ background: "#CAAE76" }}>
                                                <i className="fa fa-search text-white" />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div>
                                    {t('roles.show')}
                                    <select value={perPage} onChange={handlePerPageChange} style={{ width: "80px", height: "40px", borderRadius: "5px", margin: "0 24px 0 24px" }}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    {t('roles.entries')}
                                </div>
                            </div>
                        </div>
                        
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row mt-3">
                                    <div className="col-12">
                                    <div className="card py-3 px-3">
                                        <div className="card-body table-responsive p-0">
                                        <table className="table table-hover text-nowrap">
                                            <thead>
                                            <tr>
                                                <th className="text-center">#{t('roles.id')}</th>
                                                <th className="text-center">{t('roles.name')}</th>
                                                <th className="text-center">{t('roles.action')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((role) => (
                                                    <tr key={role.id}>
                                                        <td style={{ color: "#CAAE76" }} className="text-center">{role.id}</td>
                                                        <td style={{ color: "#909090" }} className="text-center">{role.role_name}</td>
                                                        <td className="text-center">
                                                            <Link className="border-0 bg-transparent" to={`/edit-role/${role.id}`}>
                                                                <i className="fas fa-pencil-alt nav-icon" style={{ color: "#CAAE76" }}></i>
                                                            </Link>
                                                            <button className="border-0 bg-transparent" onClick={() => deleteRole(role.id)}>
                                                                <i className="fas fa-trash-alt trash nav-icon text-danger"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center p-3">
                                        <div>
                                            {total > per_page && (
                                                <Pagination
                                                    activePage={current_page}
                                                    totalItemsCount={total}
                                                    itemsCountPerPage={per_page}
                                                    onChange={handlePageChange}
                                                    itemClass='page-item'
                                                    linkClass='page-link'
                                                    firstPageText='First'
                                                    lastPageText='Last'
                                                />
                                            )}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        )
    }

    return <main>{roles.data && renderRoleList()}</main>;
}

export default withAuthAdmin(Roles)