import { useNavigate } from "react-router-dom";
import withAuthAdmin from "../../lib/withAuthAdmin"
import { useEffect, useState } from "react";
import axios from "../../lib/axios";
import swal from "sweetalert";
import {useTranslation} from "react-i18next"
import Notification from "../../components/products/Notification";
import { ToastContainer } from "react-toastify";

const Settings = () => {
    const navigate = useNavigate();
    const [id, setId] = useState('')
    const [sarToUsdConversion, setSarToUsdConversion] = useState(0)
    const [numberProductsNotDeleted, setNumberProductsNotDeleted] = useState(0)
    const storeId = localStorage.getItem('store_id');
    const [setting, setSetting] = useState({
        prices_source: '',
        gold_ounce_price: '',
        silver_ounce_price: '',
        tax: '',
        round_price: '',
        email: '',
        cost_discount: '',
        increase_on_total: '',
        update_price_every: '',
        sar_to_usd_conversion: '',
        description_update: '',
    });

    const {t} = useTranslation()
    const platform = localStorage.getItem('platform')

    const handleInput = (e) => {
        e.persist();
        setSetting({...setting, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        const settingsEndpoint = platform === 'Salla' ? 'settings' : 'zidSettings';
        axios.get(`/api/${settingsEndpoint}?store_id=${storeId}`).then(res => {
            setId(res.data.id);
            setSarToUsdConversion(res.data.sarToUsdConversion);
            setNumberProductsNotDeleted(res.data.numberProductsNotDeleted);
        }).catch(error => {
            console.error(error);
        });
        
        axios.get(`/api/${settingsEndpoint}/${id}`).then(res => {
            if (res.data.status === 200) {
                setSetting(res.data.setting);
            } else if (res.data.status === 404) {
                navigate('/');
            }
        }).catch(error => {
            console.error(error);
        });;
    }, [id, navigate]);

    const updateSetting = async(e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('_method', 'PATCH');
        formData.append('prices_source', setting.prices_source);
        formData.append('gold_ounce_price', setting.gold_ounce_price !== "" ? setting.gold_ounce_price : 0);
        formData.append('silver_ounce_price', setting.silver_ounce_price !== "" ? setting.silver_ounce_price : 0);
        formData.append('tax', setting.tax !== "" ? setting.tax : 0);
        formData.append('round_price', setting.round_price !== "" ? setting.round_price : 0);
        formData.append('email', setting.email !== null ? setting.email : '');
        formData.append('cost_discount', setting.cost_discount !== "" ? setting.cost_discount : 0);
        formData.append('increase_on_total', setting.increase_on_total !== "" ? setting.increase_on_total : 0);
        formData.append('update_price_every', setting.update_price_every);
        formData.append('sar_to_usd_conversion', setting.sar_to_usd_conversion !== "" ? setting.sar_to_usd_conversion : 0);
        formData.append('description_update', setting.description_update);

        const settingsEndpoint = platform === 'Salla' ? 'settings' : 'zidSettings';
        await axios.post(`/api/${settingsEndpoint}/` + id, formData).then(({data})=>{
        }).catch(({response})=>{
            if (response.status === 422) {
                swal("Error", `${t('layout.fieldsRequired')}`, "error");
            } else {
                console.log(response.data.message)
                
            }
        })
    }

    async function deleteProducts() {
        try {
            const destroyAllProductsEndpoint = platform === 'Salla' ? 'destroyAllProducts' : 'zidDestroyAllProducts';
            const response = await axios.post(`/api/${destroyAllProductsEndpoint}?store_id=${storeId}`);
            console.log(response.data.message);
            swal("Success", `${t('layout.deleteSuccessfully')}`, "success");
        } catch (error) {
            console.error(error.response.data.message);
            
        }
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="second-content-wrapper my-4 py-4 rounded rounded-10" style={{ margin: "0 70px" }}>
                    <div className='d-flex justify-content-center align-items-center px-4 py-3'>
                        <div className="mb-3">
                            <h3 style={{ fontSize: "19.04px" }}>{t('settings.title')}</h3>
                        </div>
                    </div>
                    
                    <section className="content">
                        <div className="container-fluid">
                            <form onSubmit={updateSetting}>
                                <div className="card-body">
                                    <div className="form-group">
                                        <div className="row d-flex align-items-center justify-content-between mx-2">
                                            <label className="col-md-3" htmlFor="pricesSource"><span className="star-error">*</span> {t('settings.pricesSource')}</label>
                                            <select
                                                className="setting-input form-control col-md-8" id="pricesSource"
                                                name="prices_source" value={setting.prices_source} onChange={handleInput}
                                            >
                                                <option value="Gold Api">AutoGold Api</option>
                                                <option value="Manual">Manual</option>
                                            </select>
                                        </div>
                                    </div>
                                    {setting.prices_source === 'Manual' && (
                                        <div className="form-group manual">
                                        <div className="row d-flex align-items-center justify-content-between mx-2">
                                            <label className="col-md-3" htmlFor="gold_ounce_price">{t('settings.goldOuncePrice')}</label>
                                            <input
                                            type="number" className="setting-input form-control col-md-8" id="gold_ounce_price"
                                            name="gold_ounce_price" value={setting.gold_ounce_price} onChange={handleInput}
                                            />
                                        </div>
                                        </div>
                                    )}
                                    {setting.prices_source === 'Manual' && (
                                        <div className="form-group manual">
                                        <div className="row d-flex align-items-center justify-content-between mx-2">
                                            <label className="col-md-3" htmlFor="silver_ounce_price">{t('settings.silverOuncePrice')}</label>
                                            <input
                                            type="number" className="setting-input form-control col-md-8" id="silver_ounce_price"
                                            name="silver_ounce_price" value={setting.silver_ounce_price} onChange={handleInput}
                                            />
                                        </div>
                                        </div>
                                    )}
                                    <div className="form-group">
                                        <div className="row d-flex align-items-center justify-content-between mx-2">
                                            <label className="col-md-3" htmlFor="tax">{t('settings.tax')}</label>
                                            <input
                                                type="number" className="setting-input form-control col-md-8" id="tax"
                                                name="tax" value={setting.tax} onChange={handleInput}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row d-flex align-items-center justify-content-between mx-2">
                                            <label className="col-md-3" htmlFor="email">{t('settings.email')}</label>
                                            <input 
                                                type="email" className="setting-input form-control col-md-8" id="email" disabled
                                                name="email" value={localStorage.getItem('auth_email')} onChange={handleInput}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row d-flex align-items-center justify-content-between mx-2">
                                            <label className="col-md-3" htmlFor="CostDiscount">{t('settings.costDiscount')}</label>
                                            <input 
                                                type="number" className="setting-input form-control col-md-8" id="CostDiscount"
                                                name="cost_discount" value={setting.cost_discount} onChange={handleInput}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row d-flex align-items-center justify-content-between mx-2">
                                            <label className="col-md-3" htmlFor="IncreaseOnTotal">{t('settings.increaseOnTotal')}</label>
                                            <input
                                                type="number" className="setting-input form-control col-md-8" id="IncreaseOnTotal"
                                                name="increase_on_total" value={setting.increase_on_total} onChange={handleInput}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row d-flex align-items-center justify-content-between mx-2">
                                            <label className="col-md-3" htmlFor="UpdatePriceEvery"><span className="star-error">*</span> {t('settings.updatePriceEvery')}</label>
                                            <select 
                                                className="setting-input form-control col-md-8" id="UpdatePriceEvery"
                                                name="update_price_every" value={setting.update_price_every} onChange={handleInput}
                                            >
                                                <option value="15 Minute">15 Minute</option>
                                                <option value="30 Minute">30 Minute</option>
                                                <option value="One Hour">One Hour</option>
                                                <option value="6 Hours">6 Hours</option>
                                                <option value="12 Hours">12 Hours</option>
                                                <option value="24 Hours">24 Hours</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row d-flex align-items-center justify-content-between mx-2">
                                            <label className="col-md-3" htmlFor="pricesSource"><span className="star-error">*</span> {t('settings.pricesCommas')}</label>
                                            <select
                                                className="setting-input form-control col-md-8" id="pricesSource"
                                                name="round_price" value={setting.round_price} onChange={handleInput}
                                            >
                                                <option value="0">{t('settings.pricesWithComma')}</option>
                                                <option value="1">{t('settings.pricesWithoutCommas')}</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="form-group">
                                        <div className="row d-flex align-items-center justify-content-between mx-2">
                                            <label className="col-md-3" htmlFor="SARUSD">{t('settings.sarToUsdConversion')}</label>
                                            <input
 
                                                type="number" className="setting-input form-control col-md-8" id="SARUSD"
                                                name="sar_to_usd_conversion" value={setting.sar_to_usd_conversion} onChange={handleInput}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className="ml-4 mr-2 mb-3 d-flex align-items-center justify-content-between font-weight-bold" style={{ color: "rgba(0, 0, 0, 0.3)", border: "rgba(0, 0, 0, 0.1) 1px solid" }}>
                                        <div className="d-flex align-items-center" style={{ width: "50%" }}>
                                            <div className="mx-auto">
                                                <p>سعر منتج عيار <span style={{ color: "#CAAE76" }}>24</span> = (سعر الذهب * {sarToUsdConversion}) / 1000</p>
                                                <p className="mb-0">سعر منتج عيار <span style={{ color: "#CAAE76" }}>22</span> = (سعر الذهب * {sarToUsdConversion}) / 1090.9</p>
                                            </div>
                                        </div>
                                        <div style={{ width: "0.1px", height: "100px", background: "rgba(0, 0, 0, 0.1)" }}></div>
                                        <div className="d-flex align-items-center" style={{ width: "50%" }}>
                                            <div className="mx-auto">
                                                <p>سعر منتج عيار <span style={{ color: "#CAAE76" }}>21</span> = (سعر الذهب * {sarToUsdConversion}) / 1142.7</p>
                                                <p className="mb-0">سعر منتج عيار <span style={{ color: "#CAAE76" }}>18</span> = (سعر الذهب * {sarToUsdConversion}) / 1333.3</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="form-group">
                                        <div className="row d-flex align-items-center justify-content-between mx-2">
                                            <label className="col-md-3" htmlFor="DescriptionUpdate">{t('settings.descriptionUpdate')}</label>
                                            <select 
                                                className="setting-input form-control col-md-8" id="DescriptionUpdate"
                                                name="description_update" value={setting.description_update} onChange={handleInput}
                                            >
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="mt-4">
                                        <div className="row d-flex align-items-center mx-4">
                                            <button type="submit"
                                                className="update-settings btn text-white px-5"
                                                onClick={
                                                    () => {
                                                        Notification({message: t('settings.settingsUpdatedSuccessfully'),});
                                                    }
                                                }
                                            >
                                                {t('settings.edit')}
                                            </button>
                                            {numberProductsNotDeleted !== 0 ? (
                                            <button type="button" className="btn delete-products-btn" onClick={() => deleteProducts()}>{t('settings.delete')} ({numberProductsNotDeleted}) {t('settings.products')}</button>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default withAuthAdmin(Settings)