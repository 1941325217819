import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "../../lib/axios";
import swal from "sweetalert";
import withAuthAdmin from "../../lib/withAuthAdmin";
import {useTranslation} from "react-i18next"

const CreateRole = () => {
    const navigate = useNavigate();
    const [role, setRole] = useState({
        role_name: '',
        sub_roles: ''
    });

    const {t} = useTranslation()

    const handleInput = (e) => {
        e.persist();
        setRole({...role, [e.target.name]: e.target.value});
    }

    const submitRole = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('role_name', role.role_name);
        formData.append('sub_roles', role.sub_roles);

        axios.post('/api/roles', formData).then(res => {
            if (res.data.status === 200) {
                setRole({...role,
                    role_name: '',
                    sub_roles: '',
                });
                navigate("/roles");
                swal("Success", `${t('layout.addedSuccessfully')}`, "success");
            } else if (res.data.status === 422) {
                swal("Error", `${t('layout.fieldsRequired')}`, "error");
            }
        });
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1 className="m-0">{t('createRole.title')}</h1>
                        </div>
                    </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-primary">
                            <div className="card-header py-3">
                            </div>
                            <form onSubmit={submitRole}>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="name"><span className="star-error">*</span> {t('createRole.name')}</label>
                                        <input
                                            type="text" className="form-control" id="name" placeholder={t('createRole.enterName')}
                                            name="role_name" value={role.role_name} onChange={handleInput}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn send-data">{t('createRole.title')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default withAuthAdmin(CreateRole)