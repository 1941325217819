import { Route, Routes } from 'react-router-dom';
import Dashboard from './components/layouts/Dashboard';
import Home from './pages/Home';
import Login from './pages/authentication/Login';
import ProductsNotCompleted from './pages/products/ProductsNotCompleted';
import CompletedProducts from './pages/products/CompletedProducts';
import DeletedProducts from './pages/products/DeletedProducts';
import Users from './pages/users/Users';
import Roles from './pages/roles/Roles';
import Notifications from './pages/notifications/Notifications';
import TermsAndConditions from './pages/policy/TermsAndConditions';
import Policy from './pages/policy/Policy';
import Settings from './pages/settings/Settings';
import Register from './pages/authentication/Register';
import CreateUser from './pages/users/CreateUser';
import CreateRole from './pages/roles/CreateRole';
import EditRole from './pages/roles/EditRole';
import EditUser from './pages/users/EditUser';
import CreateProduct from './pages/products/CreateProduct';
import EditProduct from './pages/products/EditProduct';
import CreateSettings from './pages/settings/CreateSettings';
import AllProducts from './pages/products/AllProducts';
import CreateNotification from './pages/notifications/CreateNotification';
import EditNotification from './pages/notifications/EditNotification';
import EditMetalPrice from './pages/EditMetalPrice';
import EditProfile from './pages/EditProfile';
import NotFoundPage from './pages/NotFoundPage';
import Callback from './pages/authentication/Callback';
import UnpulledProducts from './pages/products/UnpulledProducts';
import Plans from './pages/plans/Plans';
import ComprehensivePackage from './pages/plans/ComprehensivePackage';
import ResetPassword from './pages/authentication/ResetPassword';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Dashboard />}>
          <Route index element={<Home />} />
          <Route path="all-products" element={<AllProducts />} />
          <Route path="products-not-completed" element={<ProductsNotCompleted />} />
          <Route path="completed-products" element={<CompletedProducts />} />
          <Route path="deleted-products" element={<DeletedProducts />} />
          <Route path="create-product" element={<CreateProduct />} />
          <Route path="edit-product/:id" element={<EditProduct />} />

          <Route path="unpulled-products" element={<UnpulledProducts />} />

          <Route path="users" element={<Users />} />
          <Route path="create-User" element={<CreateUser />} />
          <Route path="edit-user/:id" element={<EditUser />} />
          
          {/* <Route path="roles" element={<Roles />} />
          <Route path="create-Role" element={<CreateRole />} />
          <Route path="edit-role/:id" element={<EditRole />} /> */}

          <Route path="edit-profile" element={<EditProfile />} />

          <Route path="notifications" element={<Notifications />} />
          <Route path="create-notification" element={<CreateNotification />} />
          <Route path="edit-notification/:id" element={<EditNotification />} />

          <Route path="edit-metalPrice/:id" element={<EditMetalPrice />} />

          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="policy" element={<Policy />} />

          <Route path="settings" element={<Settings />} />
          <Route path="create-settings" element={<CreateSettings />} />

          <Route path="plans" element={<Plans />} />
          <Route path="comprehensive-package" element={<ComprehensivePackage />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="register" element={<Register />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
