import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notification = ({ message, options }) => {
    const notify = () => {
        toast.success(message, {
        position: "top-left",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        ...options, // Additional options can be passed in the `options` prop
        });
    };

    return (
        <div>
        <ToastContainer />
        {notify()}
        </div>
    );
};

export default Notification;
