const withAuthAdmin = (WrappedComponent) => {
  const Wrapper = (props) => {
    if (!(localStorage.getItem('auth_token') && localStorage.getItem("auth_role") !== "Manager")) {
        window.location.href = '/';
        return null;
    }

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
};

export default withAuthAdmin;
