import React, { useEffect } from 'react';
import { css, keyframes } from '@emotion/react';
import { RingLoader } from 'react-spinners';
import axios from '../../lib/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from "qs";

const spinAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const Callback = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const downloading = true;

    useEffect(() => {
        handleCallback();
    }, []);

    const handleCallback = async () => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get("code");
        const state = queryParams.get("state");

        if (code && state) {
            try {
                const data = {
                    code: code,
                    state: state,
                };
        
                const response = await axios.post("/api/callback", qs.stringify(data));
        
                if (response.data.status === 200) {
                    localStorage.setItem('auth_token', response.data.token);
                    localStorage.setItem('auth_name', response.data.username);
                    localStorage.setItem('auth_id', response.data.id);
                    localStorage.setItem('auth_email', response.data.email);
                    localStorage.setItem('auth_role', response.data.role);
                    localStorage.setItem("access_token", response.data.accessToken);
                    localStorage.setItem("store_id", response.data.store_id);
                    localStorage.setItem("password_exists", response.data.passwordExists);
                }
        
                if (localStorage.getItem('password_exists') === 'false') {
                    localStorage.setItem('password_exists', response.data.passwordExists);
                    navigate('/edit-profile')
                } else {
                    localStorage.setItem('password_exists', response.data.passwordExists);
                    navigate('/')
                }
            } catch (error) {
                console.error("Error during callback:", error);
            }
        }
    };

    return (
        <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {downloading && (
                <div className="spinner" css={spinnerStyle}>
                <RingLoader
                    color={'#BBF3E5'}
                    loading={true}
                    size={150}
                />
                </div>
            )}
        </div>
    );
};

const spinnerStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${spinAnimation} 2s linear infinite; /* Adjust duration as needed */
`;

export default Callback;
