import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../lib/axios";
import swal from "sweetalert";
import withAuthAdmin from "../../lib/withAuthAdmin";
import {useTranslation} from "react-i18next"

const EditNotification = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [notification, setNotification] = useState({
        type: '',
        increase_or_decrease: '',
        percentage: '',
        compare_by_days: '',
        email: ''
    });
    const platform = localStorage.getItem('platform')

    const {t} = useTranslation()

    const handleInput = (e) => {
        e.persist();
        setNotification({...notification, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        const notificationEndpoint = platform === 'Salla' ? 'notifications' : 'zidNotifications';
        axios.get(`/api/${notificationEndpoint}/${id}`).then(res => {
            if (res.data.status === 200) {
                setNotification(res.data.notification);
            } else if (res.data.status === 404) {
                navigate('/');
            }
        });
    }, [id, navigate]);

    const updateNotification = async(e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('_method', 'PATCH');
        formData.append('type', notification.type);
        formData.append('increase_or_decrease', notification.increase_or_decrease);
        formData.append('percentage', notification.percentage);
        formData.append('compare_by_days', notification.compare_by_days);
        formData.append('email', notification.email);

        const updateNotificationEndpoint = platform === 'Salla' ? 'notifications' : 'zidNotifications';
        await axios.post(`/api/${updateNotificationEndpoint}/` + id, formData).then(({data})=>{
            swal("Success", `${t('layout.updatedSuccessfully')}`, "success");
            navigate('/notifications')
        }).catch(({response})=>{
            if (response.status === 422) {
                swal("Error", `${t('layout.fieldsRequired')}`, "error");
            } else {
                console.log(response.data.message)
            }
        })
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1 className="m-0">{t('editNotification.title')}</h1>
                        </div>
                    </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-primary">
                            <div className="card-header py-3">
                            </div>
                            <form onSubmit={updateNotification}>
                                <div className="card-body">
                                <div className="form-group">
                                        <label htmlFor="type"><span className="star-error">*</span> {t('editNotification.type')}</label>
                                        <select
                                            className="form-control" id="type"
                                            name="type" value={notification.type} onChange={handleInput}
                                        >
                                            <option>Choose an option</option>
                                            <option value="gold">Gold</option>
                                            <option value="silver">Silver</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="increase_or_decrease"><span className="star-error">*</span> {t('editNotification.increaseOrDecrease')}</label>
                                        <select
                                            className="form-control" id="increase_or_decrease"
                                            name="increase_or_decrease" value={notification.increase_or_decrease} onChange={handleInput}
                                        >
                                            <option>Choose an option</option>
                                            <option value="Increase">Increase</option>
                                            <option value="Decrease">Decrease</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="percentage"><span className="star-error">*</span> {t('editNotification.percentage')}</label>
                                        <input
                                            type="number" className="form-control" id="percentage" placeholder="Enter percentage"
                                            name="percentage" value={notification.percentage} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="compare_by_days"><span className="star-error">*</span> {t('editNotification.compareByDays')}</label>
                                        <select
                                            className="form-control" id="compare_by_days"
                                            name="compare_by_days" value={notification.compare_by_days} onChange={handleInput}
                                        >
                                            <option>Choose an option</option>
                                            <option value="1">Yesterday</option>
                                            <option value="7">7 days ago</option>
                                            <option value="14">14 days ago</option>
                                            <option value="21">21 days ago</option>
                                            <option value="30">30 days ago</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email"><span className="star-error">*</span> {t('editNotification.compareByDays')}</label>
                                        <input
                                            type="email" className="form-control" id="email" placeholder="Enter email"
                                            name="email" value={notification.email} onChange={handleInput}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn send-data">{t('editNotification.title')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default withAuthAdmin(EditNotification)