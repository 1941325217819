import { Link } from 'react-router-dom';
import Avatar from '../../imgs/avatar.png'
import axios from '../../lib/axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SilverLogo from '../../imgs/silverLogo.png'
import GoldLogo from '../../imgs/goldLogo.png'
import ArrowDropUp from '../../imgs/arrow_drop_up.png'
import ArrowDropdown from '../../imgs/arrow_drop_down.png'
import Select from 'react-select';
import customStyles from './customSelectStyles'; // You can define your custom styles in a separate file


const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'ar', label: 'العربية' },
];

const Header = () => {
    const [pricesSource, setPricesSource] = useState('');
    const [priceSilverManual, setPriceSilverManual] = useState(0);
    const [priceGoldManual, setPriceGoldManual] = useState(0);

    const [sarToUsdConversion, setSarToUsdConversion] = useState(0);
    const [goldSilverApi, setGoldSilverApi] = useState([]);
    const [goldSilverManual, setGoldSilverManual] = useState([]);
    const [goldSilverPrices, setGoldSilverPrices] = useState([]);

    const storeId = localStorage.getItem('store_id');

    const {i18n} = useTranslation()
    const {t} = useTranslation()
    const platform = localStorage.getItem('platform')

    const handlePushMenuClick = () => {
        const isSidebarNarrowed = document.body.classList.contains('sidebar-collapse');
        if (isSidebarNarrowed) {
            localStorage.setItem('logo', 'bigLogo');
        } else {
            localStorage.setItem('logo', 'smallLogo');
        }

        const event = new Event('logo');
        window.dispatchEvent(event);
    };

    useEffect(() => {
        const settingsEndpoint = platform === 'Salla' ? 'settings' : 'zidSettings';
        axios.get(`/api/${settingsEndpoint}?store_id=${storeId}`).then(res => {
            setSarToUsdConversion(res.data.sarToUsdConversion);
            setPricesSource(res.data.pricesSource);
            setPriceSilverManual(res.data.priceSilverManual);
            setPriceGoldManual(res.data.priceGoldManual);
        }).catch(error => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/api/goldSelver?store_id=${storeId}`);
        
                if (response.status === 200) {
                setGoldSilverPrices(response.data.goldSilverPrices);
                } else {
                console.error('Failed to fetch data:', response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        if (goldSilverPrices) {
            setGoldSilverApi([
                {
                    caret: "24",
                    price : goldSilverPrices.caret24,
                    status : goldSilverPrices.caret24_status
                },
                {
                    caret: "22",
                    price : goldSilverPrices.caret22,
                    status : goldSilverPrices.caret22_status
                },
                {
                    caret: "21",
                    price : goldSilverPrices.caret21,
                    status : goldSilverPrices.caret21_status
                },
                {
                    caret: "18",
                    price : goldSilverPrices.caret18,
                    status : goldSilverPrices.caret18_status
                },
            ]);
        }

        setGoldSilverManual([
            {
                caret: "24",
                price: (priceGoldManual * sarToUsdConversion / 1000).toFixed(2),
            },
            {
                caret: "22",
                price: (priceGoldManual * sarToUsdConversion / 1090.9).toFixed(2),
            },
            {
                caret: "21",
                price: (priceGoldManual * sarToUsdConversion / 1142.7).toFixed(2),
            },
            {
                caret: "18",
                price: (priceGoldManual * sarToUsdConversion / 1333.3).toFixed(2),
            },
        ]);
    }, [sarToUsdConversion, goldSilverPrices, priceGoldManual]);

    const logoutSubmit = (e) => {
        e.preventDefault();
    
        axios.post(`/api/logout`, null).then(res => {
            if (res.data.status === 200) {
                localStorage.removeItem('auth_token');
                localStorage.removeItem('auth_id');
                localStorage.removeItem('auth_name');
                localStorage.removeItem('auth_email');
                localStorage.removeItem('auth_role');
                localStorage.removeItem("access_token");
                localStorage.removeItem("store_id");
                localStorage.removeItem("password_exists");
                localStorage.removeItem("settings_exists");
                localStorage.removeItem("logo");
                localStorage.removeItem("subscription_duration");
                localStorage.removeItem("i18nextLng");
                window.location.href = '/';
            }
        });
    }

    useEffect(() => {
        for (let index = 0; index < document.getElementsByClassName('lang').length; index++) {
            const element = document.getElementsByClassName('lang')[index];
            if (element.value === i18n.language) {
                element.setAttribute('selected', 'true');
            }
        }
    }, [i18n.language]);
    
    useEffect(() => {
        const customCssLink = document.getElementById('custom-css');
        const rtlCssLink = document.getElementById('rtl-css');
        const myRtlCss = document.getElementById('my-rtl-css');
    
        if (i18n.language === 'ar') {
            customCssLink.disabled = false;
            rtlCssLink.disabled = false;
            myRtlCss.disabled = true;
        } else if (i18n.language === 'en') {
            customCssLink.disabled = true;
            rtlCssLink.disabled = true;
            myRtlCss.disabled = false;
        }
    }, [i18n.language]);
    
    const onChangeLanguage = (selectedOption) => {
        i18n.changeLanguage(selectedOption.value);
    };

    return (
        <>
            {localStorage.getItem('auth_role') ? (
                <nav className="nav-gold-api main-header navbar navbar-expand navbar-light">
                    <ul className="navbar-nav">
                        { <li className='nav-item'>
                            <span className='nav-link d-flex align-items-center'>
                                <img src={SilverLogo} alt="Silver logo" width={21.39} height={16.5} />
                                <span className="type nav-link">{t('header.silver')}</span>
                                <img 
                                    src={goldSilverPrices.silver_status === 'Decrease' ? ArrowDropdown : ArrowDropUp}
                                    alt="Silver logo" width={27.19} height={27.19}
                                />
                                <span className={`price-value nav-link ${goldSilverPrices.silver_status}`}>{(Number(goldSilverPrices.silver)).toFixed(2)}</span>
                                <span className="price nav-link" style={{ color: "#FFFFFF", fontSize: "12.69px" }}>{t('header.riyals')}</span>
                            </span>
                        </li> }
                        {goldSilverApi.map((row, index) => (
                            <li className='nav-item' key={index} >
                                <span className='nav-link d-flex align-items-center'>
                                    <img src={GoldLogo} alt="Silver logo" width={21.39} height={16.5} />
                                    <span className="type nav-link">{t('header.goldKarat')} {row.caret}</span>
                                    <img
                                        src={row.status === 'Decrease' ? ArrowDropdown : ArrowDropUp}
                                        alt="Silver logo" width={27.19} height={27.19}
                                    />
                                    <span className={`price-value nav-link ${row.status}`}>{(Number(row.price)).toFixed(2)}</span>
                                    <span className="price nav-link" style={{ color: "#FFFFFF", fontSize: "12.69px" }}>{t('header.riyals')}</span>
                                </span>
                            </li>
                        ))}
                    </ul>
                </nav>
            ) : (
                ''
            )}
            <nav className="nav-gold-manual main-header navbar navbar-expand navbar-white navbar-light d-flex justify-content-between">
                <ul className="navbar-nav mx-3">
                    <li className="nav-item">
                        <span
                        style={{ cursor: 'pointer' }}
                        data-widget="pushmenu"
                        onClick={handlePushMenuClick}
                        >
                        <i className="fas fa-bars" />
                        </span>
                    </li>
                </ul>
                {pricesSource === 'Manual' ? (
                    <ul className="navbar-nav manual-price-nav">
                        <li className=''>
                            <span className="nav-link">{t('header.manualPrice')}</span>
                        </li>
                        <li className='nav-item'>
                            <span className='nav-link d-flex align-items-center'>
                                <span className="type nav-link">{t('header.silver')}</span>
                                <img src={ArrowDropUp} alt="Silver logo" width={27.19} height={27.19} />
                                <span className="price-value nav-link">{priceSilverManual}</span>
                            </span>
                        </li>
                        {goldSilverManual.map((row, index) => (
                            <li className='nav-item' key={index}>
                                <span className='nav-link d-flex align-items-center'>
                                    <span className="type nav-link">{t('header.goldKarat')} {row.caret}</span>
                                    <img src={ArrowDropUp} alt="Silver logo" width={27.19} height={27.19} />
                                    <span className="price-value nav-link">{row.price}</span>
                                </span>
                            </li>
                        ))}
                    </ul>
                ) : (
                    ''
                )}
                <ul className="navbar-nav">
                    <li className="nav-item ml-2">
                        <Select
                            className="lang"
                            options={languageOptions}
                            value={languageOptions.find((option) => option.value === i18n.language)}
                            onChange={onChangeLanguage}
                            // You can apply custom styles to the select component
                            styles={customStyles}
                        />
                    </li>
                    {localStorage.getItem('auth_role') ? (
                        <>
                            <li className="nav-item d-none d-sm-inline-block ml-3">
                                <Link to="edit-profile" className="nav-link">{t('header.hello')} {localStorage.getItem('auth_name')}</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <span style={{ cursor: 'pointer' }} className="nav-link" data-toggle="dropdown" href="#">
                                    <img src={Avatar} alt="avatar" style={{width: 25, borderRadius: '50%'}} />
                                </span>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <span style={{ cursor: 'pointer' }} className="nav-link" onClick={logoutSubmit}>{t('header.logout')}</span>
                                </div>
                            </li>
                        </>
                    ) : (
                        ''
                    )}
                </ul>
            </nav>
            
        </>
    )
}

export default Header