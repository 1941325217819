import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../lib/axios";
import swal from "sweetalert";
import withAuthAdmin from "../../lib/withAuthAdmin";
import {useTranslation} from "react-i18next"

const CreateUser = () => {
    const navigate = useNavigate();
    const [roles, setRoles] = useState([]);
    const storeId = localStorage.getItem('store_id');
    const [user, setUser] = useState({
        store_id: '',
        name: '',
        email: '',
        password: '',
        role_id: '',
    });

    const role = localStorage.getItem('auth_role')

    const {t} = useTranslation()
    const platform = localStorage.getItem('platform')

    const handleInput = (e) => {
        e.persist();
        setUser({...user, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        const usersEndpoint = platform === 'Salla' ? 'users' : 'zidUsers';
        axios.get(`/api/${usersEndpoint}`).then(res => {
            if (res.data.status === 200) {
                setRoles(res.data.roles);
            }
        }).catch(error => {
            console.error(error);
            
        });;
    }, []);

    const submitUser = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('store_id', storeId);
        formData.append('name', user.name);
        formData.append('email', user.email);
        formData.append('password', user.password);
        formData.append('role_id', user.role_id);

        const usersEndpoint = platform === 'Salla' ? 'users' : 'zidUsers';
        axios.post(`/api/${usersEndpoint}`, formData).then(res => {
            if (res.data.status === 200) {
                setUser({...user,
                    store_id: '',
                    name: '',
                    email: '',
                    password: '',
                    role_id: '',
                });
                swal("Success", `${t('layout.addedSuccessfully')}`, "success");
                navigate('/users')
            } else if (res.data.status === 422) {
                swal("Error", `${t('layout.fieldsRequired')}`, "error");
            }
        }).catch(error => {
            console.error(error);
            
        });;
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1 className="m-0">{t('createUser.title')}</h1>
                        </div>
                    </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-primary">
                            <div className="card-header py-3">
                            </div>
                            <form onSubmit={submitUser}>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="name"><span className="star-error">*</span> {t('createUser.name')}</label>
                                        <input
                                            type="text" className="form-control" id="name" placeholder={t('createUser.enterName')}
                                            name="name" value={user.name} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email"><span className="star-error">*</span> {t('createUser.emailAddress')}</label>
                                        <input
                                            type="email" className="form-control" id="email" placeholder={t('createUser.enterEmail')}
                                            name="email" value={user.email} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password"><span className="star-error">*</span> {t('createUser.password')}</label>
                                        <input
                                            type="password" className="form-control" id="password" placeholder={t('createUser.enterPassword')}
                                            name="password" value={user.password} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="role"><span className="star-error">*</span> {t('createUser.role')}</label>
                                        <select
                                            className="form-control" id="role"
                                            name="role_id" value={user.role_id} onChange={handleInput}
                                        >
                                            <option>{t('createUser.chooseARole')}</option>
                                            {
                                                role === 'Super Admin' ? (
                                                    roles.length > 0 && (
                                                        roles.map(row =>(
                                                            <option value={row.id}>{row.role_name}</option>
                                                        ))
                                                    )
                                                ) : (
                                                    roles.length > 0 && (
                                                        roles
                                                            .filter(row => row.role_name === "Manager")
                                                            .map(row => (
                                                                <option key={row.id} value={row.id}>{row.role_name}</option>
                                                            ))
                                                    )
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn send-data">{t('createUser.title')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default withAuthAdmin(CreateUser)