import {useTranslation} from "react-i18next"
import { Link } from "react-router-dom"

const NotFoundPage = () => {
    const {t} = useTranslation()

    return (
        <>
            <section className="content">
                <div className="error-page">
                    <h2 className="headline text-warning"> 404</h2>
                    <div className="error-content">
                        <h3><i className="fas fa-exclamation-triangle text-warning" /> {t('notFoundPage.oops')}</h3>
                        <p>
                            {t('notFoundPage.text')} <Link to="/">{t('notFoundPage.returnToDashboard')}</Link>
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NotFoundPage