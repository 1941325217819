import { useState, useEffect } from 'react';
import axios from '../../lib/axios';

const useGoldPrice = (storedStoreId) => {
  const [priceGold, setPriceGold] = useState(0);
  const [priceSilver, setPriceSilver] = useState(0);
  const [sarToUsdConversion, setSarToUsdConversion] = useState(0);
  const [pricesSource, setPricesSource] = useState('');
  const [goldSilver, setGoldSilver] = useState([]);
  const [goldSilverPrices, setGoldSilverPrices] = useState([]);
  const platform = localStorage.getItem('platform')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const settingsEndpoint = platform === 'Salla' ? 'settings' : 'zidSettings';
        const response = await axios.get(`/api/${settingsEndpoint}?store_id=${storedStoreId}`);
        const data = response.data;
        setSarToUsdConversion(data.sarToUsdConversion);
        setPricesSource(data.pricesSource);

        if (data.pricesSource === 'Manual') {
          setPriceSilver(data.priceSilverManual);
          setPriceGold(data.priceGoldManual);
        } else {
          const fetchGoldSilverData = async () => {
            try {
                const response = await axios.get('/api/goldSelver');
        
                if (response.status === 200) {
                  setGoldSilverPrices(response.data.goldSilverPrices);
                } else {
                console.error('Failed to fetch data:', response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
          };
      
          fetchGoldSilverData();
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };

    fetchData();
  }, [storedStoreId]);

  useEffect(() => {
    const calculateGoldSilverPrices = () => {
      if (pricesSource === 'Manual') {
        setGoldSilver([
          { caret: "18", price: (priceGold * sarToUsdConversion / 1333.3).toFixed(2) },
          { caret: "21", price: (priceGold * sarToUsdConversion / 1142.7).toFixed(2) },
          { caret: "22", price: (priceGold * sarToUsdConversion / 1090.9).toFixed(2) },
          { caret: "24", price: (priceGold * sarToUsdConversion / 1000).toFixed(2) },
          { caret: "", price: 0 },
        ]);
      } else {
        if (goldSilverPrices) {
          setGoldSilver([
            { caret: "18", price: goldSilverPrices.caret18 },
            { caret: "21", price: goldSilverPrices.caret21 },
            { caret: "22", price: goldSilverPrices.caret22 },
            { caret: "24", price: goldSilverPrices.caret24 },
            { caret: "", price: 0 },
          ]);
          setPriceSilver(goldSilverPrices.silver);
        }
      }
    };

    calculateGoldSilverPrices();
  }, [sarToUsdConversion, priceGold, goldSilverPrices, pricesSource]);

  return {
    priceGold,
    priceSilver,
    sarToUsdConversion,
    pricesSource,
    goldSilver,
  };
};

export default useGoldPrice;

