import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { imageBaseURL } from "../../lib/imageBaseURL";
import swal from "sweetalert";
import withAuthAdmin from "../../lib/withAuthAdmin";
import {useTranslation} from "react-i18next"
import axios from "axios";

const EditProduct = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [product, setProduct] = useState({
        product_name: '',
        product_url: '',
        product_type: '',
        cost: '',
        carat: '',
        weight: '',
        weight_type: '',
        fixed_cost: '',
        special_increace_value: '',
        special_increace: '',
        fixed_price: '',
        tax: '',
    });

    const [product_img, setProductImg] = useState(null);

    const {t} = useTranslation()

    const accessToken = localStorage.getItem('access_token')

    const handleInput = (e) => {
        e.persist();
        setProduct({...product, [e.target.name]: e.target.value});
    }

    const handleCheckbox = (e) => {
        if (e && e.persist) {
            e.persist();
            setProduct({...product, [e.target.name]: e.target.checked});
        }
    }

    const handleImg = (e) => {
        setProductImg({product_img: e.target.files[0]});
    }

    useEffect(() => {
        axios.get(`http://127.0.0.1:8000/api/products/${id}`).then(res => {
        // axios.get(`https://autogoldprice.com/api/products/${id}`).then(res => {
            if (res.data.status === 200) {
                setProduct(res.data.product);
                setProductImg(res.data.product.product_img);
            } else if (res.data.status === 404) {
                navigate('/');
            }
        });
    }, [id, navigate]);

    const updateProduct = async(e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('_method', 'PATCH');
        formData.append('product_name', product.product_name);
        formData.append('product_url', product.product_url);
        formData.append('product_type', product.product_type);
        formData.append('cost', product.cost !== "" ? product.cost : 0);
        formData.append('carat', product.carat !== "" ? product.carat : 'Empty');
        formData.append('weight', product.weight !== "" ? product.weight : 0);
        formData.append('weight_type', product.weight_type !== "" ? product.weight_type : null);
        formData.append('fixed_cost', product.fixed_cost);
        formData.append('special_increace_value', product.special_increace_value);
        formData.append('special_increace', product.special_increace);
        formData.append('fixed_price', product.fixed_price !== "" ? product.fixed_price : 0);
        formData.append('tax', product.tax ? '1' : '0');
        if (product_img !== null) {
            formData.append('product_img', product_img.product_img);
        }

        await axios.post('http://127.0.0.1:8000/api/products/' + id, formData).then(({data})=>{
        // await axios.post('https://autogoldprice.com/api/products/' + id, formData).then(({data})=>{
            swal("Success", 'Update successfully', "success");
            navigate('/all-products')
        }).catch(({response})=>{
            if (response.status === 422) {
                swal("Error", "Fields with (*) are required", "error");
            } else {
                console.log(response.data.message)
            }
        })

        const options = {
            method: 'PUT',
            url: `https://api.salla.dev/admin/v2/products/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: "Bearer " + accessToken
            },
            data: {
                name: product.product_name,
                price: product.fixed_price,
                weight: product.weight,
                weight_type: product.weight_type,
            }
        };

        try {
            const { data } = await axios.request(options);
            console.log(data);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1 className="m-0">{t('editProduct.title')}</h1>
                        </div>
                    </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">{t('editProduct.title')}</h3>
                            </div>
                            <form onSubmit={updateProduct}>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="product_name">{t('createProduct.name')}</label>
                                        <input
                                            type="text" className="form-control" id="product_name" placeholder="Enter name"
                                            name="product_name" value={product.product_name} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="product_img">{t('createProduct.image')}</label>
                                        {product.product_img && (
                                            <div className="mb-2">
                                            <img src={`${imageBaseURL}${product.product_img}`} alt="Product" style={{ width: '200px' }} />
                                            </div>
                                        )}
                                        <input
                                            type="file" className="form-control" id="product_img" placeholder="Enter image"
                                            name="product_img" onChange={handleImg}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="product_url">{t('createProduct.productUrl')}</label>
                                        <input
                                            type="text" className="form-control" id="product_url" placeholder="Enter url"
                                            name="product_url" value={product.product_url} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="product_type">{t('allProducts.type')}</label>
                                        <select
                                            type="text" className="form-control" id="product_type" placeholder="Enter type"
                                            name="product_type" value={product.product_type} onChange={handleInput}
                                        >
                                            <option>Choose an option</option>
                                            <option value="Gold">Gold</option>
                                            <option value="Silver">Silver</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="cost">{t('allProducts.cost')}</label>
                                        <input
                                            type="number" className="form-control" id="cost" placeholder="Enter cost"
                                            name="cost" value={product.cost} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="carat">{t('allProducts.caret')}</label>
                                        <select
                                            type="text" className="form-control" id="carat" placeholder="Enter carat"
                                            name="carat" value={product.carat} onChange={handleInput}
                                        >
                                            <option>Empty</option>
                                            <option value="18">18</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="24">24</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="weight">{t('allProducts.weight')}</label>
                                        <input
                                            type="number" className="form-control" id="weight" placeholder="Enter weight"
                                            name="weight" value={product.weight} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="weight_type">{t('allProducts.weight_type')}</label>
                                        <input
                                            type="text" className="form-control" id="weight_type" placeholder="Enter weight type"
                                            name="weight_type" value={product.weight_type} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fixed_cost">{t('allProducts.fixedCost')}</label>
                                        <input
                                            type="number" className="form-control" id="fixed_cost" placeholder="Enter fixed cost"
                                            name="fixed_cost" value={product.fixed_cost} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="special_increace_value">{t('allProducts.specialIncreaseValue')}</label>
                                        <input
                                            type="number" className="form-control" id="special_increace_value" placeholder="Enter special increace value"
                                            name="special_increace_value" value={product.special_increace_value} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="special_increace">{t('allProducts.specialIncrease')}</label>
                                        <select
                                            type="text" className="form-control" id="special_increace" placeholder="Enter special increace"
                                            name="special_increace" value={product.special_increace} onChange={handleInput}
                                        >
                                            <option>Choose an option</option>
                                            <option value="%">%</option>
                                            <option value="Sar">Sar</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fixed_price">{t('allProducts.fixedPrice')}</label>
                                        <input
                                            type="number" className="form-control" id="fixed_price" placeholder="Enter fixed price"
                                            name="fixed_price" value={product.fixed_price} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="tax">{t('allProducts.tax')}</label>
                                        <input
                                            type="checkbox" className="form-control" id="tax"
                                            name="tax" checked={product.tax} onChange={handleCheckbox}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-primary">{t('editProduct.title')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default withAuthAdmin(EditProduct)