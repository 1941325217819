import {useTranslation} from "react-i18next"

const Policy = () => {
    const {t} = useTranslation()

    return (
        <>
            <div className="content-wrapper">
                <div className="second-content-wrapper my-4 mx-5 py-4 rounded rounded-10">
                    <div className='d-flex justify-content-center align-items-center px-4 pt-3'>
                        <div>
                            <h1 style={{ fontSize: "25px" }}>{t('policy.title')}</h1>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="policy-text card-body" style={{ color: "gray" }}>
                                        {/* {t('policy.text')} */}
                                        <h2>{t('policy.monthlySubscription')}</h2>
                                        <p>{t('policy.monthlySubscriptionFirstText')}</p>
                                        <p>{t('policy.monthlySubscriptionSecondText')}</p>
                                        <h2>{t('policy.security')}</h2>
                                        <p>{t('policy.securityText')}</p>
                                        <h2>{t('policy.responsibility')}</h2>
                                        <p>{t('policy.responsibilityFirstText')}</p>
                                        <p>{t('policy.responsibilitySecondText')}</p>
                                        <h2>{t('policy.guarantees')}</h2>
                                        <p>{t('policy.guaranteesFirstText')}</p>
                                        <p>{t('policy.guaranteesSecondText')}</p>
                                        <h2>{t('policy.cancellationService')}</h2>
                                        <p>{t('policy.cancellationServiceText')}</p>
                                        <h2>{t('policy.propertyRights')}</h2>
                                        <p>{t('policy.propertyRightsFirstText')}</p>
                                        <p>{t('policy.propertyRightsSecondText')}</p>
                                        <h2>{t('policy.refrainFromHiring')}</h2>
                                        <p>{t('policy.refrainFromHiringText')}</p>
                                        <h2>{t('policy.conflictResolution')}</h2>
                                        <p>{t('policy.conflictResolutionText')}</p>
                                        <h2>{t('policy.fees')}</h2>
                                        <p>{t('policy.feesText')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Policy