import React, { useState } from "react";
import swal from "sweetalert";
import withAuthAdmin from "../lib/withAuthAdmin";
import axios from "../lib/axios";
import {useTranslation} from "react-i18next"
import { useNavigate } from "react-router-dom";

const EditProfile = () => {
    const navigate = useNavigate();
    const id = localStorage.getItem("auth_id");
    const [user, setUser] = useState({
        name: localStorage.getItem("auth_name") || "",
        email: localStorage.getItem("auth_email") || "",
        password: "",
    });

    const {t} = useTranslation()
    const platform = localStorage.getItem('platform')

    const handleInput = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const updateProfile = async (e) => {
        e.preventDefault();
    
        try {
            const formData = new FormData();
            formData.append("_method", "PATCH");
            formData.append("name", user.name);
            formData.append("email", user.email);
            
            if (user.password) {
                formData.append("password", user.password);
            }
    
            const updateProfileEndpoint = platform === 'Salla' ? 'updateProfile' : 'updateZidProfile';
            const response = await axios.post(`/api/${updateProfileEndpoint}/${id}`, formData);
            const responseData = response.data;
    
            localStorage.setItem('auth_name', responseData.updatedUser.name);
            localStorage.setItem('auth_email', responseData.updatedUser.email);

            localStorage.setItem('password_exists', responseData.passwordExists);
            swal("Success", `${t('layout.updatedSuccessfully')}`, "success");
        } catch (error) {
            if (error.response && error.response.status === 422) {
                swal("Error", `${t('layout.fieldsRequired')}`, "error");
            } else {
                console.log(error.response ? error.response.data.message : error.message);
            }
        }
    };

    return (
        <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">{t('profile.profile')}</h1>
                    </div>
                </div>
                {/* {
                    localStorage.getItem('password_exists') === 'false' ?
                    <div 
                        className="bg-warning text-center py-2"
                        style={{ marginTop: '1rem', fontSize: '1.2rem' }}>
                        <span>رجاء قم بتعيين كلمة المرور</span>
                    </div> :
                    ''
                } */}
            </div>
        </div>
        <section className="content">
            <div className="container-fluid">
            <div className="card card-primary">
                <div className="card-header py-3">
                </div>
                <form onSubmit={updateProfile}>
                <div className="card-body">
                    <div className="form-group">
                    <label htmlFor="name">{t('profile.name')}</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={user.name}
                        name="name"
                        onChange={handleInput}
                    />
                    </div>
                    <div className="form-group">
                    <label htmlFor="email">{t('profile.email')}</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={user.email}
                        name="email"
                        onChange={handleInput}
                    />
                    </div>
                    <div className="form-group">
                    <label htmlFor="password">{t('profile.password')}</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        value={user.password}
                        onChange={handleInput}
                    />
                    </div>
                </div>
                <div className="card-footer">
                    <button type="submit" className="btn send-data">
                    {t('profile.edit')}
                    </button>
                </div>
                </form>
            </div>
            </div>
        </section>
        </div>
    );
};

export default withAuthAdmin(EditProfile);
