import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../lib/axios";
import swal from "sweetalert";
import withAuthAdmin from "../../lib/withAuthAdmin";
import {useTranslation} from "react-i18next"

const EditUser = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [roles, setRoles] = useState([]);
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        role_id: '',
    });

    const role = localStorage.getItem('auth_role')

    const {t} = useTranslation()
    const platform = localStorage.getItem('platform')

    const handleInput = (e) => {
        e.persist();
        setUser({...user, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        const usersEndpoint = platform === 'Salla' ? 'users' : 'zidUsers';
        axios.get(`/api/${usersEndpoint}`).then(res => {
            if (res.data.status === 200) {
                setRoles(res.data.roles);
            }
        }).catch(error => {
            // 
        });

        axios.get(`/api/${usersEndpoint}/${id}`).then(res => {
            if (res.data.status === 200) {
                setUser(res.data.user);
            } else if (res.data.status === 404) {
                navigate('/');
            }
        }).catch(error => {
            // 
        });
    }, [id, navigate]);

    const updateUser = async(e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('_method', 'PATCH');
        formData.append('name', user.name);
        formData.append('email', user.email);
        if (user.password) {
            formData.append('password', user.password);
        }
        formData.append('role_id', user.role_id);

        const usersEndpoint = platform === 'Salla' ? 'users' : 'zidUsers';
        await axios.post(`/api/${usersEndpoint}/` + id, formData).then(({data})=>{
            swal("Success", `${t('layout.updatedSuccessfully')}`, "success");
            navigate('/users')
        }).catch(({response})=>{
            if (response.status === 422) {
                swal("Error", `${t('layout.fieldsRequired')}`, "error");
            } else {
                console.log(response.data.message)
            }
        })
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1 className="m-0">{t('editUser.title')}</h1>
                        </div>
                    </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-primary">
                            <div className="card-header py-3">
                            </div>
                            <form onSubmit={updateUser}>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="name"><span className="star-error">*</span> {t('editUser.name')}</label>
                                        <input
                                            type="text" className="form-control" id="name" placeholder="Enter name"
                                            name="name" value={user.name} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email"><span className="star-error">*</span> {t('editUser.emailAddress')}</label>
                                        <input
                                            type="email" className="form-control" id="email" placeholder="Enter email"
                                            name="email" value={user.email} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password"><span className="star-error">*</span> {t('editUser.password')}</label>
                                        <input
                                            type="password" className="form-control" id="password" placeholder={t('createUser.enterPassword')}
                                            name="password" value={user.password} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="role"><span className="star-error">*</span> {t('editUser.role')}</label>
                                        <select
                                            className="form-control" id="role"
                                            name="role_id" value={user.role_id} onChange={handleInput}
                                        >
                                            <option>Choose a role</option>
                                            {
                                                role === 'Super Admin' ? (
                                                    roles.length > 0 && (
                                                        roles.map(row =>(
                                                            <option value={row.id}>{row.role_name}</option>
                                                        ))
                                                    )
                                                ) : (
                                                    roles.length > 0 && (
                                                        roles
                                                            .filter(row => row.role_name === "Manager")
                                                            .map(row => (
                                                                <option key={row.id} value={row.id}>{row.role_name}</option>
                                                            ))
                                                    )
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn send-data">{t('editUser.title')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default withAuthAdmin(EditUser)