import { useCallback, useEffect, useState } from 'react';
import withAuth from '../../lib/withAuth'
import Pagination from 'react-js-pagination';
import {useTranslation} from "react-i18next"
import axios from '../../lib/axios';
import GoldSelver from '../../imgs/gold-selver.png'
import gridiconsAdd from '../../imgs/gridicons_add.png'
import swal from "sweetalert";

const UnpulledProducts = () => {
    const [products, setProducts] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const storedStoreId = localStorage.getItem('store_id');
    const {t} = useTranslation()
    const [selectedProducts, setSelectedProducts] = useState([]);
    const platform = localStorage.getItem('platform')

    useEffect(() => {
        getProductsData(activePage, perPage);
    }, [activePage, perPage]);

    async function getProductsData(pageNumber, pageSize) {
        try {
            const unpulledProductsEndpoint = platform === 'Salla' ? 'unpulledProducts' : 'zidUnpulledProducts';
            const response = await axios.get(`/api/${unpulledProductsEndpoint}?page=${pageNumber}&per_page=${pageSize}&store_id=${storedStoreId}`);
            
            if (response.data.status === 403) {
                console.error('Unauthorized access to store data.');
                return;
            }
            
            setProducts(response.data.products);
        } catch (error) {
            console.error(error);
            
        }
    }

    const handleSearch = useCallback(async () => {
        try {
            const searchUnpulledProductsEndpoint = platform === 'Salla' ? 'searchUnpulledProducts' : 'zidSearchUnpulledProducts';
            const response = await axios.get(`/api/${searchUnpulledProductsEndpoint}`, {
                params: { searchTerm, store_id: storedStoreId },
            });
    
            setProducts(response.data.products);
        } catch (error) {
            console.error(error);
            
        }
    }, [searchTerm]);
    
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleSearch();
        }, 500);
    
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, handleSearch]);
    
    function handlePageChange(pageNumber) {
        setActivePage(pageNumber);
    }

    function handlePerPageChange(event) {
        setPerPage(parseInt(event.target.value));
        setActivePage(1);
    }

    const handleProductSelect = (productId) => {
        if (selectedProducts.includes(productId)) {
            setSelectedProducts(selectedProducts.filter(id => id !== productId));
        } else {
            setSelectedProducts([...selectedProducts, productId]);
            console.log(selectedProducts);
        }
    };

    const handleSaveButtonClick = async () => {
        try {
            const pullProductsEndpoint = platform === 'Salla' ? 'pullProducts' : 'zidPullProducts';
            await axios.post(`/api/${pullProductsEndpoint}?store_id=${storedStoreId}`, {
                productIds: selectedProducts,
            });
            getProductsData(activePage, perPage)
            swal("Success", 'Pulled successfully', "success");
        } catch (error) {
            console.error(error);
            
        }
    };

    function renderProductList() {
        const { data, current_page, per_page, total } = products;
        return (
            <div className="content-wrapper">
                <div className="second-content-wrapper m-4 py-4 rounded rounded-10">
                    <div className="d-flex align-items-center justify-content-center pb-4">
                        <div className="page-title d-flex align-items-center justify-content-between">
                            <img src={GoldSelver} alt="dashboard" width={53} height={42} />
                            <h1 style={{ fontSize: "24px", color: "#000000" }}>{t('unpulledProducts.unpulledProducts')}</h1>
                        </div>
                    </div>
                    <section className="content mx-4">
                        <div className="container-fluid">
                            <div className="row mt-3">
                                <div className="col-12">
                                <div style={{ background: "#FFFFFF", boxShadow: "0px 4px 40px 0px #0000001A"}}>
                                    <div className='d-flex justify-content-between align-items-center px-4 py-3'>
                                        <div className="mb-3">
                                            <h3 style={{ fontSize: "19.04px" }}>{t('unpulledProducts.unpulledProducts')}</h3>
                                        </div>
                                        <button
                                            className="add-product-btn border-0 btn"
                                            onClick={handleSaveButtonClick}
                                        >
                                            <img src={gridiconsAdd} alt="Add product" />
                                            {t('unpulledProducts.save')}
                                        </button>
                                    </div>
                                    
                                    <div className="d-none d-lg-block">
                                        <div className='d-flex justify-content-between align-items-center px-4 py-3'>
                                            <form onSubmit={handleSearch}>
                                                <div className="input-group" style={{ width: "300px" }}>
                                                    <input 
                                                        type="search" className="form-control form-control-lg" placeholder={t('notifications.search')}
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                    />
                                                    <div className="input-group-append">
                                                        <button type='button' className="btn btn-lg btn-default" style={{ background: "#CAAE76" }}>
                                                            <i className="fa fa-search text-white" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div>
                                                {t('notifications.show')}
                                                <select value={perPage} onChange={handlePerPageChange} style={{ width: "80px", height: "40px", borderRadius: "5px", margin: "0 24px 0 24px" }}>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                                {t('notifications.entries')}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body table-responsive p-0" style={{ boxhadow: "0px 4.525312900543213px 45.253143310546875px 0px #0000001A" }}>
                                        <table className="table table-hover text-nowrap">
                                            <thead>
                                            <tr>
                                                <th>{t('unpulledProducts.select')}</th>
                                                <th>{t('unpulledProducts.name')}</th>
                                                <th>{t('unpulledProducts.image')}</th>
                                                <th>Sku</th>
                                                <th>{t('unpulledProducts.price')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((product) => (
                                                    <tr key={product.id}>
                                                        <td style={{ color: "#909090", width: '100px' }}>
                                                            <input
                                                                style={{ width: '100%', boxSizing: 'border-box' }}
                                                                type="checkbox"
                                                                checked={selectedProducts.includes(product.id)}
                                                                onChange={() => handleProductSelect(product.id)}
                                                            />
                                                        </td>
                                                        <td style={{ color: "#CAAE76" }}>
                                                            <a href={product.product_url} target='blank' style={{ color: "#CAAE76", textDecoration: "none" }}>{product.product_name}</a>
                                                        </td>
                                                        <td style={{ color: "#CAAE76" }}>
                                                            <img
                                                                src={product.product_img}
                                                                alt={product.product_name}
                                                                style={{ width: "30px" }}
                                                            />
                                                        </td>
                                                        <td style={{ color: "#CAAE76" }}>
                                                        </td>
                                                        <td style={{ color: "#CAAE76", width: '100px' }}>
                                                            {product.fixed_price}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center p-3">
                                        <div>
                                            {total > per_page && (
                                                <Pagination
                                                    activePage={current_page}
                                                    totalItemsCount={total}
                                                    itemsCountPerPage={per_page}
                                                    onChange={setActivePage}
                                                    itemClass='page-item'
                                                    linkClass='page-link'
                                                    firstPageText='First'
                                                    lastPageText='Last'
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }

    return <main>{products.data && renderProductList()}</main>;
}

export default withAuth(UnpulledProducts)