import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import axios from "../lib/axios";
import withAuthAdmin from "../lib/withAuthAdmin";

const EditMetalPrice = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [metalPrice, setMetalPrice] = useState({
        type: '',
        price: '',
    });

    const handleInput = (e) => {
        e.persist();
        setMetalPrice({...metalPrice, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        axios.get(`/api/metalPrices/${id}`).then(res => {
            if (res.data.status === 200) {
                setMetalPrice(res.data.metalPrice);
            } else if (res.data.status === 404) {
                navigate('/');
            }
        });
    }, [id, navigate]);

    const updateMetalPrice = async(e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('_method', 'PATCH');
        formData.append('type', metalPrice.type);
        formData.append('price', metalPrice.price);

        await axios.post('/api/metalPrices/' + id, formData).then(({data})=>{
            swal("Success", 'Update successfully', "success");
        }).catch(({response})=>{
            if (response.status === 422) {
                swal("Error", "Fields with (*) are required", "error");
            } else {
                console.log(response.data.message)
            }
        })
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1 className="m-0">Edit Metal Price</h1>
                        </div>
                    </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Edit Metal Price</h3>
                            </div>
                            <form onSubmit={updateMetalPrice}>
                                <div className="card-body">
                                <div className="form-group">
                                        <label htmlFor="type">Type</label>
                                        <select
                                            className="form-control" id="type"
                                            name="type" value={metalPrice.type} onChange={handleInput}
                                        >
                                            <option>Choose an option</option>
                                            <option value="gold">Gold</option>
                                            <option value="silver">Silver</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="price">Price</label>
                                        <input
                                            type="number" className="form-control" id="price" placeholder="Enter price"
                                            name="price" value={metalPrice.price} onChange={handleInput}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-primary">Edit Metal Price</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default withAuthAdmin(EditMetalPrice)