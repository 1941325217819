import { useState } from "react";
import axios from "../../lib/axios";
import swal from "sweetalert";

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [platform, setPlatform] = useState('Salla');

    const handleResetPassword = async (e) => {
        e.preventDefault();

        try {
            const resetPasswordEndpoint = platform === 'Salla' ? 'resetPassword' : 'zidResetPassword';
            const response = await axios.post(`/api/${resetPasswordEndpoint}`, { email });
            swal("Success", "تم إرسال كلمة السر إلى إيميلك بنجاح", "success");
        } catch (error) {
            console.error('Password reset failed', error);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePlatformChange = (e) => {
        setPlatform(e.target.value);
    };

    return (
        <>
            <div className="container d-flex align-items-center" style={{ height: "100vh" }}>
                <div style={{ width: "100%" }}>
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                <h1 className="m-0">إستعادة كلمة السر</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="card card-primary">
                                <div className="card-header py-3">
                                </div>
                                <form onSubmit={handleResetPassword}>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="email"><span className="star-error">*</span> الإيميل</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="أدخل الإيميل"
                                                name="email"
                                                value={email}
                                                onChange={handleEmailChange}
                                            />
                                        </div>
                                        <div className="input-group mb-3">
                                            <select className="form-control" id="options" name="option" onChange={handlePlatformChange}>
                                                <option value="Salla">منصة سلة</option>
                                                <option value="Zid">منصة زد</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="submit" className="btn send-data">إرسال</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default ResetPassword