import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import swal from "sweetalert";
import axios from "../../lib/axios";
import {useTranslation} from "react-i18next"
import Logo from "../../imgs/smalltransparent.png"

const Login = () => {
    const [loginInput, setLogin] = useState({
        email: '',
        password: '',
        error_list: [],
    });

    const [authUrl, setAuthUrl] = useState('');
    const [platform, setPlatform] = useState('Salla'); // Default platform

    const {t} = useTranslation()

    const handleInputLogin = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value});
    }

    const handlePlatformChange = (e) => {
        setPlatform(e.target.value);
    };

    const loginSubmit = async (e) => {
        e.preventDefault();

        const data = {
            email: loginInput.email,
            password: loginInput.password,
        };

        const endpoint = platform === 'Salla' ? '/api/login' : '/api/zidLogin';

        try {
            const loginResponse = await axios.post(endpoint, data);

            if (loginResponse.data.status === 200) {
                const {
                    token,
                    id,
                    store_id,
                    username,
                    email,
                    access_token,
                    role,
                    passwordExists,
                    settingsExists,
                    platform,
                } = loginResponse.data;

                // Store the data in localStorage
                localStorage.setItem('auth_token', token);
                localStorage.setItem('auth_id', id);
                localStorage.setItem('store_id', store_id);
                localStorage.setItem('auth_name', username);
                localStorage.setItem('auth_email', email);
                localStorage.setItem('access_token', access_token);
                localStorage.setItem('auth_role', role);
                localStorage.setItem("password_exists", passwordExists);
                localStorage.setItem("settings_exists", settingsExists);
                localStorage.setItem("platform", platform);
                localStorage.setItem("logo", "bigLogo");
                localStorage.setItem("subscription_duration", "Annual subscription");
                localStorage.setItem("i18nextLng", "ar");

                if (localStorage.getItem('settings_exists') === 'true') {
                    window.location.href = '/';
                } else if (localStorage.getItem('settings_exists') === 'false') {
                    window.location.href = '/create-settings';
                }

            } else if (loginResponse.data.status === 401) {
                swal("Warning", "Invalid credentials", "warning");
            } else {
                setLogin({ ...loginInput, error_list: loginResponse.data.validation_errors });
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    useEffect(() => {
        fetchAuthUrl();
    }, []);
    
    const fetchAuthUrl = async () => {
        try {
            const response = await axios.get("/api/authStore");
            setAuthUrl(response.data.authUrl);
        } catch (error) {
            console.error("Error fetching authentication URL:", error);
        }
    };
    
    const handleAuthClick = () => {
        window.location.href = authUrl;
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            <div className="login d-flex justify-content-between">
                <div></div>
                <div className="my-auto" style={{ transform: "scaleX(-1)", width: "450px" }}>
                    {/* <div className="login-logo">
                        <a href="../../index2.html"><b>Live</b><span style={{ color: "#FBBC05" }}>Gold</span></a>
                    </div> */}
                    <div className="d-flex justify-content-center mb-5">
                        <img src={Logo} alt="logo" width={130} />
                    </div>
                    {/* <div className="card"> */}
                        {/* <div className="card-body login-card-body"> */}
                        <p className="text-center text-white">تسجيل الدخول</p>
                        <form className="mx-auto"onSubmit={loginSubmit}  style={{ width: "65%" }} >
                            <div className="input-group">
                                <input type="email" className="form-control" placeholder="أدخل بريدك الإلكترونى"
                                    name="email" onChange={handleInputLogin} value={loginInput.email}
                                    style={{ height: "47px" }}
                                />
                            </div>
                            <div className="mb-3">
                                <span className='text-danger'>{loginInput.error_list.email}</span>
                            </div>
                            <div className="input-group">
                                <input type="password" className="form-control" placeholder="أدخل كلمة السر"
                                    name="password" onChange={handleInputLogin} value={loginInput.password}
                                    style={{ height: "47px" }}
                                />
                            </div>
                            <div className="mb-3">
                                <span className='text-danger'>{loginInput.error_list.password}</span>
                            </div>
                            <div className="input-group mb-3">
                                <select className="form-control" id="options" name="option" onChange={handlePlatformChange}>
                                    <option value="Salla">منصة سلة</option>
                                    <option value="Zid">منصة زد</option>
                                </select>
                            </div>
                            <Link to="/reset-password" className="text-white text-decoration-none">
                                نسيت كلمة السر؟
                            </Link>
                            <div className="row mt-4">
                                <div className="col-12">
                                    <button type="submit" className="login-btn btn btn-block text-white">تسجيل الدخول</button>
                                </div>
                                {/* <div className="col-4">
                                    <Link to="/register" className="btn btn-info btn-block">{t('login.register')}</Link>
                                </div> */}
                            </div>
                        </form>
                        {/* <div className="social-auth-links text-center mb-3">
                            <p>- {t('login.or')} -</p>
                            <button onClick={handleAuthClick} className="btn btn-block" style={{ background: "#BAF3E6" }}>
                                {t('login.signInUsingSalla')}
                            </button>
                        </div> */}
                        {/* </div> */}
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default Login