import withAuth from "../../lib/withAuth"
import {useTranslation} from "react-i18next"

const TermsAndConditions = () => {
    const {t} = useTranslation()

    return (
        <>
            <div className="content-wrapper">
                <div className="second-content-wrapper my-4 mx-5 py-4 rounded rounded-10">
                    <div className='d-flex justify-content-center align-items-center px-4 pt-3'>
                        <div>
                            <h3 style={{ fontSize: "19.04px" }}>{t('termsAndConditions.title')}</h3>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-body" style={{ color: "gray" }}>
                                        {t('termsAndConditions.text')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default withAuth(TermsAndConditions)