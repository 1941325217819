import PullProducts from './PullProducts'
import GoldSilverPrice from './GoldSilverPrice'
import Statistics from './Statistics'
import FilterProduct from './FilterProduct'

const Layout = (props) => {
    return (
        <>
            <PullProducts pullProducts={props.pullProducts} />
            <Statistics />
            <FilterProduct
                startDate={props.startDate} endDate={props.endDate}
                handleStartDateChange={props.handleStartDateChange}
                handleEndDateChange={props.handleEndDateChange}
                handleSubmit={props.handleSubmit}
            />
            <GoldSilverPrice />
        </>
    )
}

export default Layout