import { useState } from "react";
import { Link } from "react-router-dom"
import axios from "../../lib/axios";
import {useTranslation} from "react-i18next"

const Register = () => {
    const [registerInput, setRegister] = useState({
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        role_id: '',
        error_list: []
    });

    const {t} = useTranslation()

    const handleInputRegister = (e) => {
        e.persist();
        setRegister({...registerInput, [e.target.name]: e.target.value});
    }

    const registerSubmit = (e) => {
        e.preventDefault();

        const data = {
            name: registerInput.name,
            email: registerInput.email,
            password: registerInput.password,
            role_id: registerInput.role_id,
            password_confirmation: registerInput.password_confirmation,
        }

        axios.post(`/api/register`, data).then(res => {
            if (res.data.status === 200) {
                localStorage.setItem('auth_token', res.data.token);
                localStorage.setItem('auth_name', res.data.username);
                localStorage.setItem('auth_id', res.data.id);
                localStorage.setItem('auth_email', res.data.email);
                localStorage.setItem('auth_role', res.data.role);
                window.location.href = '/';
            } else {
                setRegister({...registerInput, error_list: res.data.validation_errors});
            }
        });
    }

    return (
        <div>
            <div className="login-box">
                <div className="login-logo">
                    <a href="../../index2.html"><b>Live</b><span style={{ color: "#FBBC05" }}>Gold</span></a>
                </div>
                <div className="card">
                    <div className="card-body login-card-body">
                    <p className="login-box-msg">{t('register.registerANewMembership')}</p>
                    <form onSubmit={registerSubmit}>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder={t('register.fullName')}
                                name="name"
                                onChange={handleInputRegister} value={registerInput.name}
                            />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                <span className="fas fa-user" />
                                </div>
                            </div>
                        </div>
                        <span className='text-danger'>{registerInput.error_list.name}</span>
                        <div className="input-group mb-3">
                            <input type="email" className="form-control" placeholder={t('register.email')}
                                name="email"
                                onChange={handleInputRegister} value={registerInput.email}
                            />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                <span className="fas fa-envelope" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder={t('register.password')}
                                name="password"
                                onChange={handleInputRegister} value={registerInput.password}
                            />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <span className='text-danger'>{registerInput.error_list.password}</span>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder={t('register.retypePassword')}
                                name="password_confirmation"
                                onChange={handleInputRegister} value={registerInput.password_confirmation}
                            />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <input type="hidden" name="role_id" onChange={handleInputRegister} value={registerInput.role_id} />
                        <span className='text-danger'>{registerInput.error_list.password_confirmation}</span>
                        <div className="row">
                        <div className="col-4">
                            <button type="submit" className="btn btn-primary btn-block">{t('register.register')}</button>
                        </div>
                        <div className="col-8">
                            <Link to="/login" className="btn btn-info btn-block">{t('register.signIn')}</Link>
                        </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register