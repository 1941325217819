import axios from "axios";

const instance = axios.create({
    // baseURL: "http://127.0.0.1:8000",
    baseURL: 'https://api.autogoldprice.com',
    withCredentials: true
});

// Add a request interceptor
instance.interceptors.request.use(
    config => {
        const authToken = localStorage.getItem('auth_token');
        if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
    },
    error => {
        // Handle request error
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // Handle response error
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('auth_token');
            localStorage.removeItem('auth_id');
            localStorage.removeItem('auth_name');
            localStorage.removeItem('auth_email');
            localStorage.removeItem('auth_role');
            localStorage.removeItem("access_token");
            localStorage.removeItem("store_id");
            localStorage.removeItem("password_exists");
            localStorage.removeItem("settings_exists");
            localStorage.removeItem("logo");
            localStorage.removeItem("subscription_duration");
            localStorage.removeItem("i18nextLng");
            window.location.href = '/login';
        } else if (error.response && error.response.status === 403) {
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);

export default instance;
