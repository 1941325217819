import { useState } from 'react';

const useDateRange = (initialStartDate = '', initialEndDate = '') => {
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    return {
        startDate,
        endDate,
        handleStartDateChange,
        handleEndDateChange,
    };
};

export default useDateRange;
