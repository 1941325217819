import { useCallback, useEffect, useState } from 'react';
import withAuth from '../../lib/withAuth'
import swal from 'sweetalert';
// import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import Layout from '../../components/products/Layout';
import {useTranslation} from "react-i18next"
import axios from '../../lib/axios';
import GoldSelver from '../../imgs/gold-selver.png'
// import gridiconsAdd from '../../imgs/gridicons_add.png'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useGoldPrice from '../../components/products/useGoldPrice';
import useDateRange from '../../components/products/useDateRange';
import Notification from '../../components/products/Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons';

const AllProducts = () => {
    const [products, setProducts] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [costDiscount, setCostDiscount] = useState(0)
    const [increaseOnTotal, setIncreaseOnTotal] = useState(0)
    const [tax, setTax] = useState(0)

    const [searchTerm, setSearchTerm] = useState('');
    const storedStoreId = localStorage.getItem('store_id');
    const role = localStorage.getItem('auth_role')
    const {t} = useTranslation()
    const platform = localStorage.getItem('platform')

    const { startDate, endDate, handleStartDateChange, handleEndDateChange } = useDateRange('');

    const { goldSilver, priceSilver } = useGoldPrice(storedStoreId);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const settingsEndpoint = platform === 'Salla' ? 'settings' : 'zidSettings';
                const response = await axios.get(`/api/${settingsEndpoint}?store_id=${storedStoreId}`);
                setCostDiscount(response.data.costDiscount);
                setIncreaseOnTotal(response.data.increaseOnTotal);
                setTax(response.data.tax);
                getProductsData(activePage, perPage);
            } catch (error) {
                console.error('Error fetching data:', error);
                
            }
        };
    
        fetchData();
    }, [activePage, perPage]);

    async function getProductsData(pageNumber, pageSize) {
        try {
            const productsEndpoint = platform === 'Salla' ? 'products' : 'zidProducts';
            const response = await axios.get(`/api/${productsEndpoint}?page=${pageNumber}&per_page=${pageSize}&store_id=${storedStoreId}`);
            
            if (response.data.status === 403) {
                console.error('Unauthorized access to store data.');
                return;
            }
            
            setProducts(response.data.products);
        } catch (error) {
            console.error(error);
            
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const filterAllProductsByDatEndpoint = platform === 'Salla' ? 'filterAllProductsByDat' : 'zidFilterAllProductsByDat';
        axios.get(`/api/${filterAllProductsByDatEndpoint}`, {
            params: {
                start_date: startDate,
                end_date: endDate,
                store_id: storedStoreId,
            },
        })
        .then(response => {
            setProducts(response.data.products);
            console.log(response.data.products);
        })
        .catch(error => {
            console.error(error);
            
        });
    };

    const handleSearch = useCallback(async () => {
        try {
            const searchAllProductsEndpoint = platform === 'Salla' ? 'searchAllProducts' : 'zidSearchAllProducts';
            const response = await axios.get(`/api/${searchAllProductsEndpoint}`, {
                params: { searchTerm, store_id: storedStoreId },
            });
    
            setProducts(response.data.products);
        } catch (error) {
            console.error(error);
            
        }
    }, [searchTerm, storedStoreId]);
    
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleSearch();
        }, 500);
    
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, handleSearch]);
    
    async function deleteProduct(id) {
        try {
            const deleteProductEndpoint = platform === 'Salla' ? 'products' : 'zidProducts';
            const response = await axios.delete(`/api/${deleteProductEndpoint}/${id}`);
            console.log(response.data.message);
            getProductsData(activePage, perPage);
            swal("Success", `${t('layoutProducts.deleteSuccessfully')}`, "success");
        } catch (error) {
            console.error(error.response.data.message);
            
        }
    }

    async function updateProduct(id, updatedProduct) {
        try {
            const updateProductEndpoint = platform === 'Salla' ? 'products' : 'zidProducts';
            const response = await axios.put(`/api/${updateProductEndpoint}/${id}?store_id=${storedStoreId}`, updatedProduct);
            console.log(response.data.message);
            getProductsData(activePage, perPage);
            setEditingProduct()
            // swal({
            //     title: 'Success',
            //     text: 'Product updated successfully',
            //     icon: 'success',
            //     timer: 1000,
            //     buttons: false,
            // });
        } catch (error) {
            console.error(error.response.data.message);
            
        }
    }

    function handlePerPageChange(event) {
        setPerPage(parseInt(event.target.value));
        setActivePage(1);
    }

    const [editingProduct, setEditingProduct] = useState(null);

    function startEditing(product) {
        setEditingProduct(product);
    }

    function isEditing(product) {
        return product.id === (editingProduct && editingProduct.id);
    }

    async function pullProducts() {
        try {
            const pullAllProductsEndpoint = platform === 'Salla' ? 'pullAllProducts' : 'zidPullAllProducts';
            const response = await axios.post(`/api/${pullAllProductsEndpoint}?store_id=${storedStoreId}`);
            console.log(response.data.message);
            getProductsData(activePage, perPage);
            if (response.data.inpulledProductsNumber === 0) {
                swal("Warning", `${t('layoutProducts.thereAreNoProductsToPulled')}`, "warning");
            } else if (response.data.pulledProductsNumber === 20 && response.data.inpulledProductsNumber !== 0) {
                swal("Warning", `${t('layoutProducts.youMustSubscribe')}`, "warning");
            } else {
                swal("Success", `${t('layoutProducts.successfullyPulled')}`, "success");
            }
        } catch (error) {
            console.error(error.response.data.message);
            
        }
    }

    const [showFields, setShowFields] = useState({});
    const [showCaret, setShowCaret] = useState({});
    useEffect(() => {
        if (products && products.data) {
            const initialShowFields = {};
            const initialShowCaret = {};
            products.data.forEach(product => {
                initialShowFields[product.id] = product.fixed_price !== '' && parseInt(product.fixed_price) > 0;
                initialShowCaret[product.id] = product.product_type === 'Gold';
            });
            setShowFields(initialShowFields);
            setShowCaret(initialShowCaret);
        }
    }, [products]);

    function renderProductList() {
        const { data, current_page, per_page, total } = products;
        return (
            <div className="content-wrapper">
                <div className="second-content-wrapper m-4 py-4 rounded rounded-10">
                    <div className="d-flex align-items-center justify-content-center pb-4">
                        <div className="page-title d-flex align-items-center justify-content-between">
                            <img src={GoldSelver} alt="dashboard" width={53} height={42} />
                            <h1 style={{ fontSize: "24px", color: "#000000" }}>{t('allProducts.title')}</h1>
                        </div>
                    </div>
                    <section className="content mx-4">
                        <div className="container-fluid">
                            <Layout
                                startDate={startDate} endDate={endDate}
                                handleStartDateChange={handleStartDateChange}
                                handleEndDateChange={handleEndDateChange}
                                handleSubmit={handleSubmit}
                                pullProducts={pullProducts}
                            />
                            <div className="row mt-3">
                                <div className="col-12">
                                <div style={{ background: "#FFFFFF", boxShadow: "0px 4px 40px 0px #0000001A"}}>
                                    <div className='d-flex justify-content-between align-items-center px-4 py-3'>
                                        <div className="mb-3">
                                            <h3 style={{ fontSize: "19.04px" }}>{t('allProducts.title')}</h3>
                                        </div>
                                        {/* <Link to="/create-product" className="add-product-btn border-0 btn">
                                            <img src={gridiconsAdd} alt="Add product" />
                                            {t('allProducts.add')}
                                        </Link> */}
                                    </div>
                                    
                                    <div className="d-none d-lg-block">
                                        <div className='d-flex justify-content-between align-items-center px-4 py-3'>
                                            <form onSubmit={handleSearch}>
                                                <div className="input-group" style={{ width: "300px" }}>
                                                    <input 
                                                        type="search" className="form-control form-control-lg" placeholder={t('notifications.search')}
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                    />
                                                    <div className="input-group-append">
                                                        <button type='button' className="btn btn-lg btn-default" style={{ background: "#CAAE76" }}>
                                                            <i className="fa fa-search text-white" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div>
                                                {t('notifications.show')}
                                                <select value={perPage} onChange={handlePerPageChange} style={{ width: "80px", height: "40px", borderRadius: "5px", margin: "0 24px 0 24px" }}>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                                {t('notifications.entries')}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body table-responsive p-0" style={{ boxhadow: "0px 4.525312900543213px 45.253143310546875px 0px #0000001A" }}>
                                        <table className="table table-hover text-nowrap">
                                            <thead>
                                            <tr>
                                                <th>{t('allProducts.product')}</th>
                                                <th style={{width: "90px" }}>{t('allProducts.type')}</th>
                                                <th>{t('allProducts.cost')}</th>
                                                <th>{t('allProducts.caret')}</th>
                                                <th>{t('allProducts.weight')}</th>
                                                <th>{t('allProducts.fixedCost')}</th>
                                                <th>{t('allProducts.specialIncreaseValue')}</th>
                                                <th>{t('allProducts.fixedPrice')}</th>
                                                <th>{t('allProducts.tax')}</th>
                                                <th>{t('allProducts.price')}</th>
                                                <th>{t('allProducts.action')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((product) => (
                                                    <tr key={product.id}>
                                                        {!showFields[product.id] /*|| (product.fixed_price === 0 || product.fixed_price === null)*/? (
                                                            <>
                                                            <td style={{ color: "#CAAE76" }}>
                                                                <img
                                                                    src={product.product_img}
                                                                    alt={product.product_name}
                                                                    style={{ width: "30px" }}
                                                                />
                                                                <a href={product.product_url} className='btn product-link' target='blank' style={{ color: "#CAAE76", textDecoration: "none" }}>{product.product_name}
                                                                <FontAwesomeIcon style={{ marginRight: 5 }} icon={faArrowRotateLeft} /></a>
                                                            </td>
                                                            <td style={{ color: "#909090" }}>
                                                                {isEditing(product) ? (
                                                                    <select
                                                                        style={{ width: '100%', boxSizing: 'border-box', padding: "4px 0" }}
                                                                        value={editingProduct.product_type}
                                                                        onChange={(e) => {
                                                                            const newValue = e.target.value;
                                                                            setEditingProduct({ ...editingProduct, product_type: e.target.value });
                                                                            setShowCaret({ ...showCaret, [product.id]: newValue === 'Gold' });
                                                                        }}
                                                                    >
                                                                        <option style={{ display: "none" }}></option>
                                                                        <option value="Gold">Gold</option>
                                                                        <option value="Silver">Silver</option>
                                                                    </select>
                                                                ) : (
                                                                    // product.product_type === "" ? "" : product.product_type
                                                                    <select
                                                                        onClick={() => startEditing(product)}
                                                                        style={{ width: '100%', boxSizing: 'border-box', padding: "4px 0" }}
                                                                        value={product.product_type}
                                                                        onChange={(e) => setEditingProduct({ ...editingProduct, product_type: e.target.value })}
                                                                    >
                                                                        <option style={{ display: "none" }}></option>
                                                                        <option value="Gold">Gold</option>
                                                                        <option value="Silver">Silver</option>
                                                                    </select>
                                                                )}
                                                            </td>
                                                            <td style={{ color: "#CAAE76" }}>{isEditing(product) ? (
                                                                <input
                                                                    style={{ width: '100%', boxSizing: 'border-box' }}
                                                                    type="number"
                                                                    value={editingProduct.cost}
                                                                    onChange={(e) => setEditingProduct({ ...editingProduct, cost: e.target.value })}
                                                                />
                                                            ) : (
                                                                // product.cost
                                                                <input
                                                                    onClick={() => startEditing(product)}
                                                                    style={{ width: '100%', boxSizing: 'border-box' }}
                                                                    type="number"
                                                                    value={product.cost}
                                                                    onChange={(e) => setEditingProduct({ ...editingProduct, cost: e.target.value })}
                                                                />
                                                            )}</td>
                                                            <td style={{ color: "#909090" }}>
                                                                {showCaret[product.id] ? (
                                                                    isEditing(product) ? (
                                                                    <select
                                                                        style={{ width: '100%', boxSizing: 'border-box', padding: "4px 0" }}
                                                                        value={editingProduct.carat}
                                                                        onChange={(e) => setEditingProduct({ ...editingProduct, carat: e.target.value })}
                                                                    >
                                                                        <option key="default"></option>
                                                                        <option key="18" value="18">18</option>
                                                                        <option key="21" value="21">21</option>
                                                                        <option key="22" value="22">22</option>
                                                                        <option key="24" value="24">24</option>
                                                                    </select>
                                                                    ) : (
                                                                    // product.carat === "" ? "" : product.carat
                                                                    <select
                                                                        onClick={() => startEditing(product)}
                                                                        style={{ width: '100%', boxSizing: 'border-box', padding: "4px 0" }}
                                                                        value={product.carat}
                                                                        onChange={(e) => setEditingProduct({ ...editingProduct, carat: e.target.value })}
                                                                    >
                                                                        <option key="default"></option>
                                                                        <option key="18" value="18">18</option>
                                                                        <option key="21" value="21">21</option>
                                                                        <option key="22" value="22">22</option>
                                                                        <option key="24" value="24">24</option>
                                                                    </select>
                                                                    )
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </td>
                                                            <td style={{ color: "#CAAE76" }}>
                                                                {isEditing(product) ? (
                                                                    <input
                                                                        style={{ width: '100%', boxSizing: 'border-box' }}
                                                                        type="number"
                                                                        value={editingProduct.weight}
                                                                        onChange={(e) => setEditingProduct({ ...editingProduct, weight: e.target.value })}
                                                                    />
                                                                ) : (
                                                                    // product.weight
                                                                    <input
                                                                        onClick={() => startEditing(product)}
                                                                        style={{ width: '100%', boxSizing: 'border-box' }}
                                                                        type="number"
                                                                        value={product.weight}
                                                                        onChange={(e) => setEditingProduct({ ...editingProduct, weight: e.target.value })}
                                                                    />
                                                                )}
                                                            </td>
                                                            <td style={{ color: "#909090" }}>{isEditing(product) ? (
                                                                <input
                                                                    style={{ width: '100%', boxSizing: 'border-box' }}
                                                                    type="number"
                                                                    value={editingProduct.fixed_cost}
                                                                    onChange={(e) => setEditingProduct({ ...editingProduct, fixed_cost: e.target.value })}
                                                                />
                                                            ) : (
                                                                <input
                                                                    onClick={() => startEditing(product)}
                                                                    style={{ width: '100%', boxSizing: 'border-box' }}
                                                                    type="number"
                                                                    value={product.fixed_cost}
                                                                    onChange={(e) => setEditingProduct({ ...editingProduct, fixed_cost: e.target.value })}
                                                                />
                                                                // product.fixed_cost
                                                            )}</td>
                                                            <td className='d-flex' style={{ color: "#CAAE76" }}>
                                                                {isEditing(product) ? (
                                                                    <input
                                                                        style={{ width: '70px', boxSizing: 'border-box' }}
                                                                        type="number"
                                                                        value={editingProduct.special_increace_value}
                                                                        onChange={(e) => setEditingProduct({ ...editingProduct, special_increace_value: e.target.value })}
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        onClick={() => startEditing(product)}
                                                                        style={{ width: '70px', boxSizing: 'border-box' }}
                                                                        type="number"
                                                                        value={product.special_increace_value}
                                                                        onChange={(e) => setEditingProduct({ ...editingProduct, special_increace_value: e.target.value })}
                                                                    />
                                                                    // product.special_increace_value
                                                                )}
                                                                {isEditing(product) ? (
                                                                    <select
                                                                        style={{ width: '50px', boxSizing: 'border-box', padding: "4px 0" }}
                                                                        value={editingProduct.special_increace}
                                                                        onChange={(e) => setEditingProduct({ ...editingProduct, special_increace: e.target.value })}
                                                                    >
                                                                        <option style={{ display: "none" }}></option>
                                                                        <option value="%">%</option>
                                                                        <option value="Sar">Sar</option>
                                                                    </select>
                                                                ) : (
                                                                    // product.special_increace
                                                                    <select
                                                                        onClick={() => startEditing(product)}
                                                                        style={{ width: '50px', boxSizing: 'border-box', padding: "4px 0" }}
                                                                        value={product.special_increace}
                                                                        onChange={(e) => setEditingProduct({ ...editingProduct, special_increace: e.target.value })}
                                                                    >
                                                                        <option style={{ display: "none" }}></option>
                                                                        <option value="%">%</option>
                                                                        <option value="Sar">Sar</option>
                                                                    </select>
                                                                )}
                                                            </td>
                                                            <td style={{ color: "#CAAE76" }}>{isEditing(product) ? (
                                                                <input
                                                                    style={{ width: '100%', boxSizing: 'border-box' }}
                                                                    type="number"
                                                                    value={editingProduct.fixed_price}
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value;
                                                                        setEditingProduct({ ...editingProduct, fixed_price: newValue });
                                                                        setShowFields({ ...showFields, [product.id]: newValue !== '' && parseInt(newValue) > 0 });
                                                                    }}
                                                                />
                                                            ) : (
                                                                <input
                                                                    onClick={() => startEditing(product)}
                                                                    style={{ width: '100%', boxSizing: 'border-box' }}
                                                                    type="number"
                                                                    value={product.fixed_price}
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value;
                                                                        setEditingProduct({ ...editingProduct, fixed_price: newValue });
                                                                        // setShowFields({ ...showFields, [product.id]: newValue !== '' && parseInt(newValue) > 0 });
                                                                    }}
                                                                />
                                                                // product.fixed_price
                                                            )}</td>
                                                            <td style={{ color: "#909090" }}>
                                                                {isEditing(product) ? (
                                                                    <input
                                                                        style={{ width: '100%', boxSizing: 'border-box' }}
                                                                        type="checkbox"
                                                                        onChange={() => {
                                                                            const updatedProduct = { ...editingProduct, tax: !editingProduct.tax };
                                                                            setEditingProduct(updatedProduct);
                                                                            startEditing(product);
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        // onClick={() => startEditing(product)}
                                                                        style={{ width: '100%', boxSizing: 'border-box' }}
                                                                        type="checkbox"
                                                                        checked={product.tax}
                                                                        onChange={() => {
                                                                            const updatedProduct = { ...product, tax: !product.tax };
                                                                            setEditingProduct(updatedProduct);
                                                                            startEditing(updatedProduct);
                                                                        }}
                                                                    />
                                                                    // product.tax ? "Yes" : "No"
                                                                )}
                                                            </td>
                                                            <td style={{ color: "#CAAE76" }}>{product.price}</td>
                                                            <td style={{ width: '100px' }}>
                                                                {isEditing(product) ? (
                                                                    <>
                                                                        <button 
                                                                            className="edit-product border-0 btn text-white"
                                                                            onClick={
                                                                                () => {
                                                                                    Notification({message: t('layoutProducts.productUpdatedSuccessfully'),});
                                                                                    updateProduct(product.id, editingProduct);
                                                                                }
                                                                            }
                                                                        >
                                                                            {/* <i className="fas fa-check nav-icon text-info"></i> */}
                                                                            {t('layoutProducts.save')}
                                                                        </button>
                                                                        {/* <button className="border-0 bg-transparent" onClick={stopEditing}>
                                                                            <i className="fas fa-times nav-icon text-warning"></i>
                                                                        </button> */}
                                                                    </>
                                                                ) : (
                                                                    <button 
                                                                        className="edit-product border-0 btn text-white"
                                                                        onClick={
                                                                            () => {
                                                                                Notification({message: t('layoutProducts.productUpdatedSuccessfully'),});
                                                                            }
                                                                        }
                                                                    >
                                                                        {t('layoutProducts.save')}
                                                                    </button>
                                                                )}
                                                                {role !== 'Manager' ? (
                                                                    <button className="border-0 bg-transparent" onClick={() => deleteProduct(product.id)}>
                                                                        <i className="fas fa-trash-alt nav-icon text-danger"></i>
                                                                    </button>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </td>
                                                            </>
                                                        ) : (
                                                            <>
                                                            <td>
                                                                <img
                                                                    src={product.product_img}
                                                                    alt={product.product_name}
                                                                    style={{ width: "30px" }}
                                                                />
                                                                <a href={product.product_url} target='blank' style={{ color: "#CAAE76", textDecoration: "none" }}>{product.product_name}
                                                                <FontAwesomeIcon style={{ marginRight: 5 }} icon={faArrowRotateLeft} /></a>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td style={{ color: "#CAAE76" }}>{isEditing(product) ? (
                                                                <input
                                                                    style={{ width: '100%', boxSizing: 'border-box' }}
                                                                    type="number"
                                                                    value={editingProduct.fixed_price}
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value;
                                                                        setEditingProduct({ ...editingProduct, fixed_price: newValue });
                                                                        setShowFields({ ...showFields, [product.id]: newValue !== '' && parseInt(newValue) > 0 });
                                                                    }}
                                                                />
                                                            ) : (
                                                                <input
                                                                    onClick={() => startEditing(product)}
                                                                    style={{ width: '100%', boxSizing: 'border-box' }}
                                                                    type="number"
                                                                    value={product.fixed_price}
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value;
                                                                        setEditingProduct({ ...editingProduct, fixed_price: newValue });
                                                                        // setShowFields({ ...showFields, [product.id]: newValue !== '' && parseInt(newValue) > 0 });
                                                                    }}
                                                                />
                                                                // product.fixed_price
                                                            )}</td>
                                                            <td style={{ color: "#909090" }}>
                                                                {isEditing(product) ? (
                                                                    <input
                                                                        style={{ width: '100%', boxSizing: 'border-box' }}
                                                                        type="checkbox"
                                                                        onChange={() => {
                                                                            const updatedProduct = { ...editingProduct, tax: !editingProduct.tax };
                                                                            setEditingProduct(updatedProduct);
                                                                            startEditing(updatedProduct);
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        onClick={() => startEditing(product)}
                                                                        style={{ width: '100%', boxSizing: 'border-box' }}
                                                                        type="checkbox"
                                                                        checked={product.tax}
                                                                        onChange={() => {
                                                                            const updatedProduct = { ...product, tax: !product.tax };
                                                                            setEditingProduct(updatedProduct);
                                                                            startEditing(updatedProduct);
                                                                        }}
                                                                    />
                                                                    // product.tax ? "Yes" : "No"
                                                                )}
                                                            </td>
                                                            {product.tax === 0 || product.tax === null ? (
                                                                <td style={{ color: "#CAAE76" }}>{product.fixed_price}</td>
                                                            ) : (
                                                                <td style={{ color: "#CAAE76" }}>{product.fixed_price + product.fixed_price * (tax / 100)}</td>
                                                            )}
                                                            <td style={{ width: '100px' }}>
                                                                {isEditing(product) ? (
                                                                    <>
                                                                        <button 
                                                                            className="edit-product border-0 btn text-white"
                                                                            onClick={
                                                                                () => {
                                                                                    Notification({message: t('layoutProducts.productUpdatedSuccessfully'),});
                                                                                    updateProduct(product.id, editingProduct);
                                                                                }
                                                                            }
                                                                        >
                                                                            {/* <i className="fas fa-check nav-icon text-info"></i> */}
                                                                            {t('layoutProducts.save')}
                                                                        </button>
                                                                        {/* <button className="border-0 bg-transparent" onClick={stopEditing}>
                                                                            <i className="fas fa-times nav-icon text-warning"></i>
                                                                        </button> */}
                                                                    </>
                                                                ) : (
                                                                    <button 
                                                                        className="edit-product border-0 btn text-white"
                                                                        onClick={
                                                                            () => {
                                                                                Notification({message: t('layoutProducts.productUpdatedSuccessfully'),});
                                                                            }
                                                                        }
                                                                    >
                                                                        {t('layoutProducts.save')}
                                                                    </button>
                                                                )}
                                                                {role !== 'Manager' ? (
                                                                    <button className="border-0 bg-transparent" onClick={() => deleteProduct(product.id)}>
                                                                        <i className="fas fa-trash-alt nav-icon text-danger"></i>
                                                                    </button>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </td>
                                                            </>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center p-3">
                                        <div>
                                            {total > per_page && (
                                                <Pagination
                                                    activePage={current_page}
                                                    totalItemsCount={total}
                                                    itemsCountPerPage={per_page}
                                                    onChange={setActivePage}
                                                    itemClass='page-item'
                                                    linkClass='page-link'
                                                    firstPageText='First'
                                                    lastPageText='Last'
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <ToastContainer />
            </div>
        )
    }

    return <main>{products.data && renderProductList()}</main>;
}

export default withAuth(AllProducts)