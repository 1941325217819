import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "../../lib/axios";
import swal from "sweetalert";
import withAuthAdmin from "../../lib/withAuthAdmin";
import {useTranslation} from "react-i18next"

const CreateProduct = () => {
    const navigate = useNavigate();
    const [product, setProduct] = useState({
        product_name: '',
        product_url: '',
        product_type: '',
        cost: '',
        carat: '',
        weight: '',
        fixed_cost: '',
        special_increace_value: '',
        special_increace: '',
        fixed_price: '',
        tax: '',
    });

    const [product_img, setProductImg] = useState([]);

    const {t} = useTranslation()
    const authToken = localStorage.getItem('auth_token')

    const handleInput = (e) => {
        e.persist();
        setProduct({...product, [e.target.name]: e.target.value});
    }

    const handleCheckbox = (e) => {
        e.persist();
        setProduct({...product, [e.target.name]: e.target.checked});
    }

    const handleImg = (e) => {
        setProductImg({product_img: e.target.files[0]});
    }

    const submitProduct = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('product_name', product.product_name);
        formData.append('product_url', product.product_url);
        formData.append('product_img', product_img.product_img);
        formData.append('product_type', product.product_type);
        formData.append('cost', product.cost !== "" ? product.cost : 0);
        formData.append('carat', product.carat !== "" ? product.carat : 'Empty');
        formData.append('weight', product.weight !== "" ? product.weight : 0);
        formData.append('fixed_cost', product.fixed_cost);
        formData.append('special_increace_value', product.special_increace_value);
        formData.append('special_increace', product.special_increace);
        formData.append('fixed_price', product.fixed_price !== "" ? product.fixed_price : 0);
        formData.append('tax', product.tax ? '1' : '0');

        axios.post('/api/products', formData,
            {
                headers: {
                    Authorization: 'Bearer ' + authToken
                }
            }
        ).then(res => {
            if (res.data.status === 200) {
                setProduct({...product,
                    product_name: '',
                    product_url: '',
                    product_type: '',
                    cost: '',
                    carat: '',
                    weight: '',
                    fixed_cost: '',
                    special_increace_value: '',
                    special_increace: '',
                    fixed_price: '',
                    tax: false,
                });
                setProductImg({...product_img,
                    product_img: ''
                });
                navigate("/all-products");
                swal("Success", 'Add successfully', "success");
            } else if (res.data.status === 422) {
                swal("Error", "Fields with (*) are required", "error");
            }
        }).catch(error => {
            console.error(error);
            
        });;
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1 className="m-0">{t('createProduct.title')}</h1>
                        </div>
                    </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-primary">
                            <div className="card-header py-3">
                            </div>
                            <form onSubmit={submitProduct}>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="product_name">{t('createProduct.name')}</label>
                                        <input
                                            type="text" className="form-control" id="product_name" placeholder={t('createProduct.enterName')}
                                            name="product_name" value={product.product_name} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="product_img">{t('createProduct.image')}</label>
                                        <input
                                            type="file" className="form-control" id="product_img"
                                            name="product_img" onChange={handleImg}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="product_url">{t('createProduct.productUrl')}</label>
                                        <input
                                            type="text" className="form-control" id="product_url" placeholder={t('createProduct.enterUrl')}
                                            name="product_url" value={product.product_url} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="product_type">{t('allProducts.type')}</label>
                                        <select
                                            type="text" className="form-control" id="product_type"
                                            name="product_type" value={product.product_type} onChange={handleInput}
                                        >
                                            <option>{t('createProduct.chooseAnOption')}</option>
                                            <option value="Gold">Gold</option>
                                            <option value="Silver">Silver</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="cost">{t('allProducts.cost')}</label>
                                        <input
                                            type="number" className="form-control" id="cost" placeholder={t('createProduct.enterCost')}
                                            name="cost" value={product.cost} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="carat">{t('allProducts.caret')}</label>
                                        <select
                                            type="text" className="form-control" id="carat"
                                            name="carat" value={product.carat} onChange={handleInput}
                                        >
                                            <option>Empty</option>
                                            <option value="18">18</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="24">24</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="weight">{t('allProducts.weight')}</label>
                                        <input
                                            type="number" className="form-control" id="weight" placeholder={t('createProduct.enterWeight')}
                                            name="weight" value={product.weight} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fixed_cost">{t('allProducts.fixedCost')}</label>
                                        <input
                                            type="number" className="form-control" id="fixed_cost" placeholder={t('createProduct.enterFixedCost')}
                                            name="fixed_cost" value={product.fixed_cost} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="special_increace_value">{t('allProducts.specialIncreaseValue')}</label>
                                        <input
                                            type="number" className="form-control" id="special_increace_value" placeholder={t('createProduct.enterSpecialIncreaceValue')}
                                            name="special_increace_value" value={product.special_increace_value} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="special_increace">{t('allProducts.specialIncrease')}</label>
                                        <select
                                            type="text" className="form-control" id="special_increace"
                                            name="special_increace" value={product.special_increace} onChange={handleInput}
                                        >
                                            <option>{t('createProduct.chooseAnOption')}</option>
                                            <option value="%">%</option>
                                            <option value="Sar">Sar</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fixed_price">{t('allProducts.fixedPrice')}</label>
                                        <input
                                            type="number" className="form-control" id="fixed_price" placeholder={t('createProduct.enterFixedPrice')}
                                            name="fixed_price" value={product.fixed_price} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="tax">{t('allProducts.tax')}</label>
                                        <input
                                            type="checkbox" className="form-control" id="tax"
                                            name="tax" defaultChecked={product.tax === 1 ? true : false} onChange={handleCheckbox}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="send-data btn">{t('createProduct.title')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default withAuthAdmin(CreateProduct)