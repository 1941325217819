import { Link } from "react-router-dom"
import withAuthAdmin from "../../lib/withAuthAdmin"
import { useCallback, useEffect, useState } from "react";
import axios from "../../lib/axios";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import {useTranslation} from "react-i18next"
import gridiconsAdd from '../../imgs/gridicons_add.png'

function Notifications() {
    const [notifications, setNotifications] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [perPage, setPerPage] = useState(10);
    const storeId = localStorage.getItem('store_id');
    const {t} = useTranslation()
    const platform = localStorage.getItem('platform')

    useEffect(() => {
        getNotificationsData(activePage, perPage);
    }, [activePage, perPage]);

    async function getNotificationsData(pageNumber, pageSize) {
        try {
            const notificationsEndpoint = platform === 'Salla' ? 'notifications' : 'zidNotifications';
            const response = await axios.get(`/api/${notificationsEndpoint}?page=${pageNumber}&per_page=${pageSize}&store_id=${storeId}`);
            setNotifications(response.data.notifications);
        } catch (error) {
            console.error(error);
        }
    }

    const handleSearch = useCallback(async () => {
        try {
            const searchNotificationsEndpoint = platform === 'Salla' ? 'searchNotifications' : 'zidSearchNotifications';
            const response = await axios.get(`api/${searchNotificationsEndpoint}`, {
                params: {
                    searchTerm,
                    store_id: storeId 
                },
            });
    
            setNotifications(response.data.notifications);
        } catch (error) {
            console.error(error);
        }
    }, [searchTerm]);
    
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleSearch();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, handleSearch]);

    async function deleteNotification(id) {
        try {
            const deleteNotificationEndpoint = platform === 'Salla' ? 'notifications' : 'zidNotifications';
            const response = await axios.delete(`/api/${deleteNotificationEndpoint}/${id}`);
            console.log(response.data.message);
            getNotificationsData(activePage, perPage);
            swal("Success", `${t('layout.deleteSuccessfully')}`, "success");
        } catch (error) {
            console.error(error.response.data.message);
        }
    }

    function handlePageChange(pageNumber) {
        setActivePage(pageNumber);
    }

    function handlePerPageChange(event) {
        setPerPage(parseInt(event.target.value));
        setActivePage(1);
    }

    function renderNotificationList() {
        const { data, current_page, per_page, total } = notifications;
        return (
            <>
                <div className="content-wrapper">
                    <div className="second-content-wrapper m-4 py-4 rounded rounded-10">
                        <div className='d-flex justify-content-between align-items-center px-4 py-3'>
                            <div className="mb-3">
                                <h3 style={{ fontSize: "19.04px" }}>{t('notifications.title')}</h3>
                            </div>
                            <Link to="/create-notification" className="add-product-btn border-0 btn">
                                <img src={gridiconsAdd} alt="Add product" />
                                {t('notifications.add')}
                            </Link>
                        </div>

                        <div className="d-none d-lg-block">
                            <div className='d-flex justify-content-between align-items-center px-4 py-3'>
                                <form onSubmit={handleSearch}>
                                    <div className="input-group" style={{ width: "300px", height: "40px" }}>
                                        <input 
                                            type="search" className="form-control form-control-lg" placeholder={t('notifications.search')}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <button type='button' className="btn btn-lg btn-default" style={{ background: "#CAAE76" }}>
                                                <i className="fa fa-search text-white" />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div>
                                    {t('notifications.show')}
                                    <select value={perPage} onChange={handlePerPageChange} style={{ width: "80px", height: "40px", borderRadius: "5px", margin: "0 24px 0 24px" }}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    {t('notifications.entries')}
                                </div>
                            </div>
                        </div>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row mt-3">
                                    <div className="col-12">
                                    <div className="card py-3 px-3">
                                        <div className="card-body table-responsive p-0">
                                        <table className="table table-hover text-nowrap">
                                            <thead>
                                            <tr>
                                                <th className="text-center">#{t('notifications.id')}</th>
                                                <th className="text-center">{t('notifications.type')}</th>
                                                <th className="text-center">{t('notifications.highOrLow')}</th>
                                                <th className="text-center">{t('notifications.percentage')}</th>
                                                <th className="text-center">{t('notifications.comparison')}</th>
                                                <th className="text-center">{t('notifications.action')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((notification) => (
                                                    <tr key={notification.id}>
                                                        <td style={{ color: "#CAAE76" }} className="text-center">{notification.id}</td>
                                                        <td style={{ color: "#909090" }} className="text-center">{notification.type}</td>
                                                        <td style={{ color: "#CAAE76" }} className="text-center">{notification.increase_or_decrease}</td>
                                                        <td style={{ color: "#909090" }} className="text-center">{notification.percentage}</td>
                                                        <td style={{ color: "#CAAE76" }} className="text-center">{notification.compare_by_days}</td>
                                                        <td className="text-center">
                                                        <Link className="border-0 bg-transparent" to={`/edit-notification/${notification.id}`}>
                                                            <i className="fas fa-pencil-alt nav-icon" style={{ color: "#CAAE76" }}></i>
                                                        </Link>
                                                        <button className="border-0 bg-transparent" onClick={() => deleteNotification(notification.id)}>
                                                            <i className="fas fa-trash-alt trash nav-icon text-danger"></i>
                                                        </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center p-3">
                                        <div>
                                            {total > per_page && (
                                                <Pagination
                                                    activePage={current_page}
                                                    totalItemsCount={total}
                                                    itemsCountPerPage={per_page}
                                                    onChange={handlePageChange}
                                                    itemClass='page-item'
                                                    linkClass='page-link'
                                                    firstPageText='First'
                                                    lastPageText='Last'
                                                />
                                            )}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        )
    }

    return <main>{notifications.data && renderNotificationList()}</main>;
}

export default withAuthAdmin(Notifications)