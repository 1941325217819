import { useNavigate } from "react-router-dom";
import withAuth from "../../lib/withAuth"
import { useState } from "react";
import axios from "../../lib/axios";
import swal from "sweetalert";
import {useTranslation} from "react-i18next"

const CreateNotification = () => {
    const navigate = useNavigate();
    const storeId = localStorage.getItem('store_id');
    const [notification, setNotification] = useState({
        store_id: '',
        type: '',
        increase_or_decrease: '',
        percentage: '',
        compare_by_days: '',
        email: ''
    });
    const platform = localStorage.getItem('platform')

    const {t} = useTranslation()

    const handleInput = (e) => {
        e.persist();
        setNotification({...notification, [e.target.name]: e.target.value});
    }

    const submitNotification = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('store_id', storeId);
        formData.append('type', notification.type);
        formData.append('increase_or_decrease', notification.increase_or_decrease);
        formData.append('percentage', notification.percentage);
        formData.append('compare_by_days', notification.compare_by_days);
        formData.append('email', notification.email);

        const notificationsEndpoint = platform === 'Salla' ? 'notifications' : 'zidNotifications';
        axios.post(`/api/${notificationsEndpoint}`, formData).then(res => {
            if (res.data.status === 200) {
                setNotification({...notification,
                    store_id: '',
                    type: '',
                    increase_or_decrease: '',
                    percentage: '',
                    compare_by_days: '',
                    email: ''
                });
                navigate("/notifications");
                swal("Success", `${t('layout.addedSuccessfully')}`, "success");
            } else if (res.data.status === 422) {
                swal("Error", `${t('layout.fieldsRequired')}`, "error");
            }
        });
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1 className="m-0">{t('createNotification.title')}</h1>
                        </div>
                    </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-primary">
                            <div className="card-header py-3">
                            </div>
                            <form onSubmit={submitNotification}>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="type"><span className="star-error">*</span> {t('createNotification.type')}</label>
                                        <select
                                            className="form-control" id="type"
                                            name="type" value={notification.type} onChange={handleInput}
                                        >
                                            <option>{t('createNotification.chooseAnOption')}</option>
                                            <option value="gold">Gold</option>
                                            <option value="silver">Silver</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="increase_or_decrease"><span className="star-error">*</span> {t('createNotification.increaseOrDecrease')}</label>
                                        <select
                                            className="form-control" id="increase_or_decrease"
                                            name="increase_or_decrease" value={notification.increase_or_decrease} onChange={handleInput}
                                        >
                                            <option>{t('createNotification.chooseAnOption')}</option>
                                            <option value="Increase">Increase</option>
                                            <option value="Decrease">Decrease</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="percentage"><span className="star-error">*</span> {t('createNotification.percentage')}</label>
                                        <input
                                            type="number" className="form-control" id="percentage" placeholder={t('createNotification.enterPercentage')}
                                            name="percentage" value={notification.percentage} onChange={handleInput}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="compare_by_days"><span className="star-error">*</span> {t('createNotification.compareByDays')}</label>
                                        <select
                                            className="form-control" id="compare_by_days"
                                            name="compare_by_days" value={notification.compare_by_days} onChange={handleInput}
                                        >
                                            <option>{t('createNotification.chooseAnOption')}</option>
                                            <option value="1">Yesterday</option>
                                            <option value="7">7 days ago</option>
                                            <option value="14">14 days ago</option>
                                            <option value="21">21 days ago</option>
                                            <option value="30">30 days ago</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email"><span className="star-error">*</span> {t('createNotification.email')}</label>
                                        <input
                                            type="email" className="form-control" id="email" placeholder={t('createNotification.enterEmail')}
                                            name="email" value={notification.email} onChange={handleInput}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn send-data">{t('createNotification.title')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default withAuth(CreateNotification)