const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
    //   border: '2px solid',
      borderColor: state.isFocused ? 'red' : provided.borderColor, // Change border color to red when focused
      borderRadius: '4px',
      boxShadow: state.isFocused ? '0 0 4px rgba(255, 0, 0, 0.5)' : provided.boxShadow, // Change shadow when focused
      '&:hover': {
        borderColor: state.isFocused ? '#CAAE76' : provided.borderColor, // Change border color on hover
      },
    }),
    // Other styles...
  };
  
  export default customSelectStyles;